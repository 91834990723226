import * as React from 'react';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';

function pageFromLocation(location) {
  return location.pathname + location.search;
}

function trackPage(page) {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.set({ page });
    ReactGA.pageview(page);
  }
}

/**
 * Monitors changes in react-routers location object to track page views.
 */
class TrackPageView extends React.Component {
  componentDidMount() {
    const page = pageFromLocation(this.props.location);
    trackPage(page);
  }

  componentDidUpdate(prevProps) {
    const page = pageFromLocation(this.props.location);
    const prevPage = pageFromLocation(prevProps.location);

    if (page !== prevPage) {
      trackPage(page);
    }
  }

  render() {
    return React.Children.only(this.props.children);
  }
}

export default withRouter(TrackPageView);
