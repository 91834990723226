import * as React from 'react';
import { descending, ascending } from 'd3-array';
import cx from 'classnames';
import debounce from 'lodash.debounce';
import { Menu, MenuList, MenuButton, MenuItem } from '@reach/menu-button';
import '@reach/menu-button/styles.css';
// import {
//   Combobox,
//   ComboboxInput,
//   ComboboxPopover,
//   ComboboxList,
//   ComboboxOption,
//   ComboboxOptionText,
// } from '@reach/combobox';
// import '@reach/combobox/styles.css';
import AutocompleteSelect from './AutocompleteSelect';
import CloseIcon from './CloseIcon';
import { GridIcon, PhylloIcon, MapIcon, LineIcon, DownChevron } from './Icons';
import { Layouts } from './constants';

const Group = ({ children, className, noMarginBottom }) => (
  <div className={cx('pl-3', { 'mb-10': !noMarginBottom }, className)}>
    {children}
  </div>
);

const GroupHeader = ({ children, className }) => (
  <h3
    className={cx(
      'uppercase text-sm font-semibold tracking-widest text-gray-600 mb-2',
      className
    )}
  >
    {children}
  </h3>
);

const GroupIconButton = ({
  children,
  className,
  active,
  disabled,
  icon,
  ...other
}) => (
  <button
    className={cx(
      'text-left border-gray-700 leading-0  border p-1 rounded text-white hover:text-gray-400  focus:outline-none outline-none focus:shadow-outline',
      {
        'text-yellow-400 hover:text-yellow-400': active,
        'opacity-50 cursor-default hover:text-gray-600 text-gray-600': disabled,
      },
      className
    )}
    disabled={disabled}
    {...other}
  >
    {icon === 'grid' ? (
      <GridIcon className="fill-current w-5 h-5" />
    ) : icon === 'phyllo' ? (
      <PhylloIcon className="fill-current w-5 h-5" />
    ) : icon === 'map' ? (
      <MapIcon className="fill-current w-5 h-5" />
    ) : icon === 'line' ? (
      <LineIcon className="fill-current w-5 h-5" />
    ) : null}
  </button>
);

const GroupItem = ({
  children,
  label,
  className,
  activeItem,
  disabled,
  layouts,
  onClick,
  ...other
}) => {
  const layoutValues = layouts.map(d => d.value);
  const active = layoutValues.includes(activeItem);

  return (
    <div
      className={cx(
        'w-full text-left py-1 pl-2 -mx-2 mb-1 rounded text-lg text-gray-400  focus:outline-none outline-none focus:shadow-outline',
        {
          'text-yellow-400 hover:text-yellow-400': active,
          'opacity-50 cursor-default hover:text-gray-600 text-gray-600': disabled,
        },
        className
      )}
      {...other}
    >
      <div className="flex-grow mb-1">{label}</div>
      {layouts.map(layout => (
        <GroupIconButton
          className="mr-2"
          key={layout.value}
          onClick={() => {
            onClick(layout.value);
          }}
          icon={layout.icon}
          active={activeItem === layout.value}
        />
      ))}
    </div>
  );
};

const GroupItemButton = ({
  children,
  className,
  active,
  disabled,
  fontSize = 'lg',
  ...other
}) => (
  <button
    className={cx(
      'block w-full text-left py-1 px-2 -mx-2 mb-1 rounded text-white hover:text-gray-400  focus:outline-none outline-none focus:shadow-outline',
      `text-${fontSize}`,
      {
        'text-yellow-400 hover:text-yellow-400': active,
        'opacity-50 cursor-default hover:text-gray-600 text-gray-600': disabled,
      },
      className
    )}
    disabled={disabled}
    {...other}
  >
    {children}
  </button>
);

const GroupItemButtonInline = ({
  children,
  className,
  active,
  disabled,
  ...other
}) => (
  <button
    className={cx(
      'border border-gray-600 text-left py-1 px-2 mr-2 mb-1 rounded text-lg text-white hover:text-gray-400  focus:outline-none outline-none focus:shadow-outline',
      {
        'text-yellow-400 hover:text-yellow-400': active,
        'opacity-50 cursor-default hover:text-gray-600 text-gray-600': disabled,
      },
      className
    )}
    disabled={disabled}
    {...other}
  >
    {children}
  </button>
);

const GroupItemMenuButton = ({
  children,
  className,
  disabled,
  label,
  items,
  value,
  onChange,
  disableClear = false,
  ...other
}) => {
  const activeItem =
    value == null
      ? undefined
      : items.find(d => (d.value == null ? d === value : d.value === value));
  return (
    <div className="flex">
      <Menu>
        <MenuButton
          className={cx(
            'overflow-hidden whitespace-no-wrap block w-full text-left py-1 px-2 -mx-2 mb-1 rounded text-lg text-white hover:text-gray-400  focus:outline-none outline-none focus:shadow-outline',
            {
              'text-yellow-400 hover:text-yellow-400': activeItem != null,
              'opacity-50 cursor-default hover:text-gray-600 text-gray-600': disabled,
            },
            className
          )}
        >
          {value ? (
            <>
              {label && (
                <span className="block text-gray-400 tracking-wide text-xs">
                  {label}
                </span>
              )}{' '}
              {activeItem.label == null ? activeItem : activeItem.label}
            </>
          ) : (
            label
          )}{' '}
          <span aria-hidden>
            <DownChevron className="ml-1 inline fill-current h-3 w-3" />
          </span>
        </MenuButton>
        <MenuList className="overflow-auto" style={{ maxHeight: '300px' }}>
          {items.map((item, i) => (
            <MenuItem
              key={i}
              onSelect={() => onChange(item.value == null ? item : item.value)}
            >
              <span
                className={cx({
                  'font-bold text-yellow-200 hover:text-yellow-400':
                    activeItem === item,
                })}
              >
                {item.label ? item.label : item}
              </span>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      {!disableClear && value != null && (
        <button
          className="leading-none p-3 -mr-3 ml-2 hover:text-gray-400 text-gray-700 focus:outline-none"
          onClick={() => onChange(undefined)}
        >
          <CloseIcon width={10} />
        </button>
      )}
    </div>
  );
};

const GroupItemComboBox = ({
  className,
  disabled,
  label,
  items,
  value,
  onChange,
  disableClear = false,
  ...other
}) => {
  const activeItem =
    value == null
      ? undefined
      : items.find(d => (d.value == null ? d === value : d.value === value));
  const handleSelect = player => {
    onChange(player ? player.value : null);
  };

  return <AutocompleteSelect onChange={handleSelect} items={items} />;
};

const Sidebar = ({
  layoutItem,
  onChangeLayoutItem,
  stats,
  statKey,
  onStatKeyChange,
  players,
  playerFilter,
  onPlayerFilterChange,
  teams,
  teamFilter,
  onTeamFilterChange,
  onHideFilteredOut,
  hideFilteredOut,
  onShowHelp,
}) => {
  const playerItems = React.useMemo(() => {
    const playerItems = players.slice();
    playerItems.sort((a, b) =>
      descending(a.totalStats[statKey], b.totalStats[statKey])
    );
    return playerItems;
  }, [players]);

  const teamItems = React.useMemo(() => {
    const items = Object.keys(teams).map(teamId => ({
      value: teams[teamId].id,
      label: teams[teamId].full_name,
    }));
    items.sort((a, b) => ascending(a.label, b.label));
    return items;
  }, [teams]);

  return (
    <div className="bg-black p-3 overflow-auto" style={{ width: '250px' }}>
      <header className="flex pl-3 mb-4 items-center">
        <div className="flex-grow">
          <h1 className="font-bold text-lg tracking-wide">NBA 3D</h1>
          <h2 className="text-gray-600">
            By{' '}
            <a className="hover:underline" href="https://peterbeshai.com">
              Peter Beshai
            </a>
          </h2>
        </div>
        <button
          className="w-8 h-8 text-gray-100 border border-gray-600 rounded-full hover:bg-gray-800 focus:outline-none"
          onClick={onShowHelp}
        >
          ?
        </button>
      </header>
      <Group>
        <GroupHeader>Visualize</GroupHeader>
        {/*<GroupItemMenuButton
          value={statKey}
          onChange={onStatKeyChange}
          items={stats}
          disableClear
        /> */}
        <div className="flex flex-wrap">
          {stats.map(d => (
            <GroupItemButtonInline
              key={d.value}
              value={d.value}
              onClick={() => {
                onStatKeyChange(d.value);
              }}
              active={statKey === d.value}
            >
              {d.labelShort}
            </GroupItemButtonInline>
          ))}
        </div>
      </Group>
      <Group>
        <GroupHeader>Layout</GroupHeader>
        <GroupItem
          label="Player"
          activeItem={layoutItem}
          layouts={[Layouts.players, Layouts['players-phyllo']]}
          onClick={onChangeLayoutItem}
        />
        <GroupItem
          label="Player × Team"
          activeItem={layoutItem}
          layouts={[Layouts.grid, Layouts['phyllo']]}
          onClick={onChangeLayoutItem}
        />
        <GroupItem
          label="Team"
          activeItem={layoutItem}
          layouts={[
            Layouts['team'],
            Layouts['team-phyllo'],
            Layouts['team-geo'],
            Layouts['team-row'],
          ]}
          onClick={onChangeLayoutItem}
        />
        <GroupItemButton
          value={'nba-logo'}
          onClick={() => {
            onChangeLayoutItem('nba-logo');
          }}
          active={layoutItem === 'nba-logo'}
        >
          NBA
        </GroupItemButton>
      </Group>
      <Group noMarginBottom>
        <GroupHeader>Filter</GroupHeader>
        <GroupItemComboBox
          value={playerFilter}
          onChange={onPlayerFilterChange}
          items={playerItems}
          label={'Player'}
        />

        <GroupItemMenuButton
          value={teamFilter}
          onChange={onTeamFilterChange}
          items={teamItems}
          label={'Team'}
        />
        <GroupItemButton
          onClick={() => {
            onHideFilteredOut(!hideFilteredOut);
          }}
          active={hideFilteredOut}
          className="mt-4 text-gray-600"
          fontSize="base"
        >
          Hide Filtered{hideFilteredOut && ' ✓'}
        </GroupItemButton>
      </Group>
    </div>
  );
};

export default Sidebar;
