import React, { useRef, useCallback } from 'react';

export default function useMouse(items, onSelectInstance, onHoverInstance) {
  // ------------------------ //
  // ----- MOUSE EVENTS ----- //
  // ------------------------ //

  const mouseRef = useRef([0, 0]);
  // track mousedown position to skip click handlers on drags
  const mouseDownRef = useRef([0, 0]);
  const handlePointerDown = e => {
    mouseDownRef.current[0] = e.clientX;
    mouseDownRef.current[1] = e.clientY;
  };

  const handleClick = useCallback(
    e => {
      console.log('click', e);
      // did we drag? - check distance from mouse down
      const downDistance = Math.sqrt(
        Math.pow(mouseDownRef.current[0] - e.clientX, 2) +
          Math.pow(mouseDownRef.current[1] - e.clientY, 2)
      );
      if (downDistance > 5) {
        e.stopPropagation();
        return;
      }
      // const item = items[e.instanceId];
      const item = items.find(d => d.instanceId === e.instanceId);
      const point = { x: item.x, y: item.y, z: item.z };
      onSelectInstance({
        item,
      });
    },
    [onSelectInstance]
  );
  const handlePointerMove = useCallback(e => {
    // const mouse = [
    //   e.clientX - window.innerWidth / 2,
    //   e.clientY - window.innerHeight / 2,
    // ];
    // // e.point gives us the point in world coordinates
    // mouseRef.current[0] = mouse[0];
    // mouseRef.current[1] = mouse[1];
    mouseRef.current[0] = e.point.x; //mouse[0];
    mouseRef.current[1] = -e.point.y; //mouse[0];
    e.stopPropagation();

    const item = items.find(d => d.instanceId === e.instanceId); // TODO:perf: could use a lookup here if we need perf
    onHoverInstance({
      item,
      mouse: [e.clientX, e.clientY],
    });
  }, []);

  const handlePointerLeave = useCallback(
    e => {
      onHoverInstance(null);
    },
    [onHoverInstance]
  );

  return {
    handleClick,
    handlePointerDown,
    handlePointerMove,
    handlePointerLeave,
  };
}
