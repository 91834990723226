function applySearchQueryFilter(items, searchQuery) {
  let filtered = items;
  // apply text filter
  if (searchQuery != null && searchQuery.length > 0) {
    const searchRegex = new RegExp(
      `(?:^|\\b)${searchQuery
        .replace(/[^\w\s]/g, '') // remove non word characters
        .split(' ')
        .map(d => `${d}.*`)
        .join(' ')}`,
      'i'
    );
    filtered = filtered.filter(d => searchRegex.test(d.player_name));
  }

  return filtered;
}

function applyPlayerFilter(items, playerFilter) {
  let filtered = items;
  if (playerFilter != null) {
    filtered = filtered.filter(d => d.player_id === playerFilter);
  }

  return filtered;
}

function applyTeamFilter(items, teamFilter) {
  let filtered = items;
  if (teamFilter != null) {
    filtered = filtered.filter(d => d.team_id === teamFilter);
  }

  return filtered;
}

export function applyFilters(items, filters = {}, options = {}) {
  const { searchQuery, playerFilter, teamFilter } = filters;
  const { treatPlayersAsOne, players } = options;
  let filtered = items;
  // apply text filter
  filtered = applySearchQueryFilter(filtered, searchQuery);

  // apply player filter
  filtered = applyPlayerFilter(filtered, playerFilter);

  // // apply team filter
  filtered = applyTeamFilter(filtered, teamFilter);

  // mark those filtered with a filtered flag (so we can render them differently while always rendering all objects)
  for (const item of items) {
    item.filteredIn = false;
  }
  for (const item of filtered) {
    item.filteredIn = true;
  }
  items.filteredLength = filtered.length;
  items.hasFilters = items.filteredLength !== items.length;

  if (treatPlayersAsOne) {
    items.filteredLength = 0;
    for (const player of players) {
      const filteredIn = player.items.some(d => d.filteredIn);
      if (filteredIn) {
        for (const item of player.items) {
          item.filteredIn = filteredIn;
        }
        items.filteredLength += player.items.length;
      }
    }
  }

  items.filterTimestamp = performance.now();
  return items;
}

export default applyFilters;
