import { max, scaleLinear } from 'd3';
export const MIN_HEIGHT = 0.3;

export function applyHeights(items, attr, { extent, hideFilteredOut } = {}) {
  const heightScale = scaleLinear()
    .domain(extent || [0, max(items, d => d[attr])])
    .range([MIN_HEIGHT, 150]);
  for (const item of items) {
    // no filter
    if (items.filteredLength === items.length || item.filteredIn) {
      item.height = heightScale(item[attr]);
    } else {
      item.height = hideFilteredOut ? MIN_HEIGHT : heightScale(item[attr]);
    }
  }

  items.heightTimestamp = performance.now();

  return items;
}
