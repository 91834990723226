import * as React from 'react';
import cx from 'classnames';

const CloseIcon = ({ className, width = 16 }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      className={cx('height-font-size', className)}
    >
      <line
        x1={0}
        x2={16}
        y1={0}
        y2={16}
        stroke="currentColor"
        strokeWidth={2}
      />
      <line
        x1={0}
        x2={16}
        y1={16}
        y2={0}
        stroke="currentColor"
        strokeWidth={2}
      />
    </svg>
  );
};

export default CloseIcon;
