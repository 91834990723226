import * as THREE from 'three';

export const vertexShader = `
#define STANDARD
varying vec3 vViewPosition;
#ifndef FLAT_SHADED
	varying vec3 vNormal;
	#ifdef USE_TANGENT
		varying vec3 vTangent;
		varying vec3 vBitangent;
	#endif
#endif
#include <common>

// MODIFICATIONS START
attribute vec3 instanceColor;
attribute vec3 prevInstanceColor;
varying vec3 vInstanceColor;
uniform vec2 mouse;
varying vec2 vMouse;
uniform float time;
uniform float animateTranslateDuration;
uniform float animateTranslateStartTime;
uniform float animateColorDuration;
uniform float animateColorStartTime;
attribute vec3 prevPosition;
attribute vec3 currPosition; // this isn't the position attr since we use the matrix and translates
attribute float prevHeight;
attribute float currHeight;

float easeCubicInOut(float t) {
  return (t /= 0.5) < 1.0 ? 0.5 * t * t * t : 0.5 * ((t-=2.0) * t * t + 2.0);
}



// MODIFICATIONS END


#include <uv_pars_vertex>
#include <uv2_pars_vertex>
#include <displacementmap_pars_vertex>
#include <color_pars_vertex>
#include <fog_pars_vertex>
#include <morphtarget_pars_vertex>
#include <skinning_pars_vertex>
#include <shadowmap_pars_vertex>
#include <logdepthbuf_pars_vertex>
#include <clipping_planes_pars_vertex>
void main() {
	#include <uv_vertex>
	#include <uv2_vertex>
	#include <color_vertex>
	#include <beginnormal_vertex>
	#include <morphnormal_vertex>
	#include <skinbase_vertex>
	#include <skinnormal_vertex>
	#include <defaultnormal_vertex>
#ifndef FLAT_SHADED
	vNormal = normalize( transformedNormal );
	#ifdef USE_TANGENT
		vTangent = normalize( transformedTangent );
		vBitangent = normalize( cross( vNormal, vTangent ) * tangent.w );
	#endif
#endif
	#include <begin_vertex>

  // MODIFICATIONS START
  // vInstanceColor = instanceColor;
	float colorElapsed = time - animateColorStartTime;
	float colorT = 1.0 - clamp(colorElapsed / animateColorDuration, 0.0, 1.0);
	colorT = easeCubicInOut(colorT);
	vInstanceColor = mix(instanceColor, prevInstanceColor, colorT);
  // MODIFICATIONS END

  #include <morphtarget_vertex>
	#include <skinning_vertex>
	#include <displacementmap_vertex>

  // MODIFICATIONS START
	// this was #include <project_vertex>
	vec4 mvPosition = vec4( transformed, 1.0 );
	#ifdef USE_INSTANCING
		mvPosition = instanceMatrix * mvPosition;
		// NOTE current position is really 0,0,0 given the instance matrix
		// NOTE the instance matrix position is what is used for click events
		// so while displaced by prev position, click events will not work

		float translateElapsed = time - animateTranslateStartTime;
		float translateT = 1.0 - clamp(translateElapsed / animateTranslateDuration, 0.0, 1.0);
		translateT = easeCubicInOut(translateT);

		float prevItemY = prevPosition.y + 0.5 * prevHeight;
		float currItemY = currPosition.y + 0.5 * currHeight;

		vec4 relativePrevPosition = vec4(
			prevPosition.x - currPosition.x,
			0.0,//prevItemY * prevHeight * 0.0 - currItemY, // * currHeight,
			prevPosition.z - currPosition.z,
			0.0);

		// animat position
		mvPosition.x += relativePrevPosition.x * translateT;
		mvPosition.z += relativePrevPosition.z * translateT;

		// animate the height and position
		// reset back to 0
		mvPosition.y = (mvPosition.y - currItemY) / currHeight + 0.5;
		// set to the previous H
		mvPosition.y *= mix(currHeight, prevHeight, translateT);
		mvPosition.y += mix(currPosition.y, prevPosition.y, translateT); // * prevHeight;

	#endif
	mvPosition = modelViewMatrix * mvPosition;
	gl_Position = projectionMatrix * mvPosition;
  // MODIFICATIONS END




	#include <logdepthbuf_vertex>
	#include <clipping_planes_vertex>
	vViewPosition = - mvPosition.xyz;
	#include <worldpos_vertex>
	#include <shadowmap_vertex>
  #include <fog_vertex>


  // MODIFICATIONS START
	// mvPosition = vec4(mvPosition.x, mvPosition.y, mvPosition.z, mvPosition[3]);
	// float dist = distance(mouse, mvPosition.xy);
	// float warpRadius = 4.0;
	// float warpAmount = 0.20;
	// if (dist < warpRadius) {

	// 	float pct = ((warpRadius - dist) / warpRadius);
	// 	mvPosition.z += sqrt(pct) * warpAmount;
	// 	vInstanceColor = vec3(1.0, 0.,0.);
	// }
  // gl_Position = projectionMatrix * mvPosition;
	// // vInstanceColor = vec3(mouse.xy * 0.001, 1.0);
	vMouse = mouse;
}
`;

export const fragmentShader = `
#define STANDARD
#ifdef PHYSICAL
	#define REFLECTIVITY
	#define CLEARCOAT
	#define TRANSPARENCY
#endif
uniform vec3 diffuse;
uniform vec3 emissive;
uniform float roughness;
uniform float metalness;
uniform float opacity;
#ifdef TRANSPARENCY
	uniform float transparency;
#endif
#ifdef REFLECTIVITY
	uniform float reflectivity;
#endif
#ifdef CLEARCOAT
	uniform float clearcoat;
	uniform float clearcoatRoughness;
#endif
#ifdef USE_SHEEN
	uniform vec3 sheen;
#endif
varying vec3 vViewPosition;
#ifndef FLAT_SHADED
	varying vec3 vNormal;
	#ifdef USE_TANGENT
		varying vec3 vTangent;
		varying vec3 vBitangent;
	#endif
#endif
#include <common>

// MODIFICATIONS START
varying vec3 vInstanceColor;
varying vec2 vMouse;
// MODIFICATIONS END

#include <packing>
#include <dithering_pars_fragment>
#include <color_pars_fragment>
#include <uv_pars_fragment>
#include <uv2_pars_fragment>
#include <map_pars_fragment>
#include <alphamap_pars_fragment>
#include <aomap_pars_fragment>
#include <lightmap_pars_fragment>
#include <emissivemap_pars_fragment>
#include <bsdfs>
#include <cube_uv_reflection_fragment>
#include <envmap_common_pars_fragment>
#include <envmap_physical_pars_fragment>
#include <fog_pars_fragment>
#include <lights_pars_begin>
#include <lights_physical_pars_fragment>
#include <shadowmap_pars_fragment>
#include <bumpmap_pars_fragment>
#include <normalmap_pars_fragment>
#include <clearcoat_normalmap_pars_fragment>
#include <roughnessmap_pars_fragment>
#include <metalnessmap_pars_fragment>
#include <logdepthbuf_pars_fragment>
#include <clipping_planes_pars_fragment>
void main() {
	#include <clipping_planes_fragment>

  // MODIFICATIONS START
  vec4 diffuseColor = vec4( diffuse * vInstanceColor, opacity );
  // MODIFICATIONS END

  ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
	vec3 totalEmissiveRadiance = emissive;
	#include <logdepthbuf_fragment>
	#include <map_fragment>
	#include <color_fragment>
	#include <alphamap_fragment>
	#include <alphatest_fragment>
	#include <roughnessmap_fragment>
	#include <metalnessmap_fragment>
	#include <normal_fragment_begin>
	#include <normal_fragment_maps>
	#include <clearcoat_normal_fragment_begin>
	#include <clearcoat_normal_fragment_maps>
	#include <emissivemap_fragment>
	#include <lights_physical_fragment>
	#include <lights_fragment_begin>
	#include <lights_fragment_maps>
	#include <lights_fragment_end>
	#include <aomap_fragment>
	vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + reflectedLight.directSpecular + reflectedLight.indirectSpecular + totalEmissiveRadiance;
	#ifdef TRANSPARENCY
		diffuseColor.a *= saturate( 1. - transparency + linearToRelativeLuminance( reflectedLight.directSpecular + reflectedLight.indirectSpecular ) );
	#endif
	gl_FragColor = vec4( outgoingLight, diffuseColor.a );
	#include <tonemapping_fragment>
	#include <encodings_fragment>
	#include <fog_fragment>
	#include <premultiplied_alpha_fragment>
	#include <dithering_fragment>

	// float dist = distance(vMouse, gl_FragCoord.xy);
	// if (dist > 10.0) {
	// 	gl_FragColor = vec4(vec3(dist) / 10.0, 1.0);
	// }
}
`;

export default function customMaterial(options = {}) {
  const material = new THREE.MeshStandardMaterial({
    metalness: 0.0,
    roughness: 1.0,
    color: 0xffffff,
    side: THREE.DoubleSide,
    ...options,
  });
  var colorParsChunk = `
      attribute vec3 instanceColor;
      varying vec3 vInstanceColor;
    `;

  var instanceColorChunk = `
      vInstanceColor = instanceColor;
    `;

  var fragmentParsChunk = `
      varying vec3 vInstanceColor;
    `;

  var colorChunk = `
      vec4 diffuseColor = vec4( diffuse * vInstanceColor, opacity );
    `;

  material.onBeforeCompile = function(shader) {
    shader.vertexShader = vertexShader;
    shader.fragmentShader = fragmentShader;
    shader.uniforms = {
      ...shader.uniforms,
      mouse: { value: new THREE.Vector2(1.0, 0.0) },
      time: { value: 0.0 },
      animateTranslateDuration: { value: 1.0 }, // seconds
      animateTranslateStartTime: { value: -100.0 }, // negative value so initial render doesn't run animation
      animateColorDuration: { value: 1.0 }, // seconds
      animateColorStartTime: { value: -100.0 },
    };
    // shader.vertexShader = shader.vertexShader
    //   .replace('#include <common>\n', `#include <common>\n${colorParsChunk}`)
    //   .replace(
    //     '#include <begin_vertex>\n',
    //     `#include <begin_vertex>\n${instanceColorChunk}`
    //   );
    // console.log(shader.vertexShader);
    // console.log('--------^^ VERTEX ^^ -----------');
    // shader.fragmentShader = shader.fragmentShader
    //   .replace('#include <common>\n', `#include <common>${fragmentParsChunk}`)
    //   .replace('vec4 diffuseColor = vec4( diffuse, opacity );\n', colorChunk);
    // console.log(shader.fragmentShader);
    // console.log('--------^^ FRAGMENT ^^ -----------');

    material.uniforms = shader.uniforms;
  };

  console.log(material.uniforms);
  return material;
}

// alternate approach
// https://gist.github.com/mattdesl/034c5daf2cf5a01c458bc9584cbe6744
function MeshCustomMaterial(parameters) {
  THREE.MeshStandardMaterial.call(this);
  this.uniforms = THREE.UniformsUtils.merge([
    THREE.ShaderLib.standard.uniforms,
    {
      time: { value: 0.0 },

      // your custom uniforms or overrides to built-ins
    },
  ]);
  setFlags(this);
  this.setValues(parameters);
}

MeshCustomMaterial.prototype = Object.create(
  THREE.MeshStandardMaterial.prototype
);
MeshCustomMaterial.prototype.constructor = MeshCustomMaterial;
MeshCustomMaterial.prototype.isMeshStandardMaterial = true;

MeshCustomMaterial.prototype.copy = function(source) {
  THREE.MeshStandardMaterial.prototype.copy.call(this, source);
  this.uniforms = THREE.UniformsUtils.clone(source.uniforms);
  setFlags(this);
  return this;
};

function setFlags(material) {
  material.vertexShader = vertexShader;
  material.fragmentShader = fragmentShader;
  material.type = 'MeshCustomMaterial';
}
