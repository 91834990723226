import React, { useMemo, useEffect } from 'react';
import * as THREE from 'three';

export default function useItemLayout(meshRef, items) {
  const dummy = useMemo(() => new THREE.Object3D(), []);

  // ------------------------ //
  // ----- POSITIONING  ----- //
  // ------------------------ //
  // update instance positions
  useEffect(() => {
    const mesh = meshRef.current;
    const numItems = items.length;
    const { attributes } = mesh.geometry;
    // initialize numItems
    const prevPosition = attributes.prevPosition
      ? attributes.prevPosition.array
      : new Float32Array(numItems * 3).fill(0);
    const currPosition = attributes.currPosition
      ? attributes.currPosition.array
      : new Float32Array(numItems * 3).fill(0);
    const prevHeight = attributes.prevHeight
      ? attributes.prevHeight.array
      : new Float32Array(numItems * 1).fill(1);
    const currHeight = attributes.currHeight
      ? attributes.currHeight.array
      : new Float32Array(numItems * 1).fill(1);

    for (var i = 0; i < numItems; i++) {
      const item = items[i];
      if (item.instanceId == null) {
        //can't rely on i after first render since we re-order the array
        item.instanceId = i;
      }
      item.index = i;
      dummy.position.set(item.x, item.y + 0.5 * item.height, item.z);
      dummy.scale.set(1, item.height, 1);
      // dummy.rotation.set(0.5 * Math.PI, 0.0 * Math.PI, 0); // needed for cylinder
      dummy.updateMatrix();
      mesh.setMatrixAt(item.instanceId, dummy.matrix);
      prevPosition[item.instanceId * 3 + 0] =
        currPosition[item.instanceId * 3 + 0];
      prevPosition[item.instanceId * 3 + 1] =
        currPosition[item.instanceId * 3 + 1];
      prevPosition[item.instanceId * 3 + 2] =
        currPosition[item.instanceId * 3 + 2];
      currPosition[item.instanceId * 3 + 0] = item.x;
      currPosition[item.instanceId * 3 + 1] = item.y;
      currPosition[item.instanceId * 3 + 2] = item.z;

      // update height attributes
      prevHeight[item.instanceId] = currHeight[item.instanceId];
      currHeight[item.instanceId] = item.height;
    }
    mesh.instanceMatrix.needsUpdate = true;

    // haven't initialized position attributes
    if (attributes.prevPosition == null) {
      mesh.geometry.setAttribute(
        'prevPosition',
        new THREE.InstancedBufferAttribute(prevPosition, 3)
      );
      mesh.geometry.setAttribute(
        'currPosition',
        new THREE.InstancedBufferAttribute(currPosition, 3)
      );
      mesh.geometry.setAttribute(
        'prevHeight',
        new THREE.InstancedBufferAttribute(prevHeight, 1)
      );
      mesh.geometry.setAttribute(
        'currHeight',
        new THREE.InstancedBufferAttribute(currHeight, 1)
      );
    }
    attributes.prevPosition.needsUpdate = true;
    attributes.currPosition.needsUpdate = true;
    attributes.prevHeight.needsUpdate = true;
    attributes.currHeight.needsUpdate = true;
    const material = meshRef.current.material;
    if (material.uniforms) {
      material.uniforms.animateTranslateStartTime.value =
        performance.now() / 1000;
    }
  }, [dummy, items, items.layoutTimestamp, items.heightTimestamp]);
}
