import React, { useMemo, useRef } from 'react';
import { useFrame } from 'react-three-fiber';
import customMaterial from './customMaterial';
import SelectedItem from './SelectedItem';
import useColors from './useColors';
import useItemLayout from './useItemLayout';
import useMouse from './useMouse';

const IntancedPlayers = ({
  players,
  selectedInstance,
  onSelectInstance,
  hoverInstance,
  onHoverInstance,
  itemsAreGrouped,
}) => {
  const items = players;
  const numItems = items.length;
  const meshRef = useRef();
  const customStandardMaterial = useMemo(
    () => customMaterial({ metalness: 0.0, roughness: 0.9 }),
    []
  );

  useColors(meshRef, items, hoverInstance, selectedInstance, itemsAreGrouped);
  const {
    handleClick,
    handlePointerMove,
    handlePointerDown,
    handlePointerLeave,
  } = useMouse(items, onSelectInstance, onHoverInstance);
  useItemLayout(meshRef, items);

  // update the shader for animations with the current time
  useFrame(() => {
    const material = meshRef.current.material;
    if (material.uniforms) {
      material.uniforms.time.value = performance.now() / 1000; // seconds
    }
  });

  return (
    <>
      <SelectedItem selectedInstance={selectedInstance} />
      <instancedMesh
        ref={meshRef}
        args={[null, null, numItems]}
        onClick={handleClick}
        frustumCulled={false}
        onPointerLeave={handlePointerLeave}
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        material={customStandardMaterial}
        castShadow
      >
        <cylinderBufferGeometry
          attach="geometry"
          args={[0.6, 0.6, 1.0, 32, 1]}
        />
      </instancedMesh>
    </>
  );
};

export default IntancedPlayers;
