import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as THREE from 'three';

const vertexShader = `
varying vec3 vWorldPosition;
void main() {
  vec4 worldPosition = modelMatrix * vec4( position, 1.0 );
  vWorldPosition = worldPosition.xyz;
  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}
`;

const fragmentShader = `
uniform vec3 topColor;
uniform vec3 bottomColor;
uniform float offset;
uniform float exponent;
varying vec3 vWorldPosition;
void main() {
  float h = normalize( vWorldPosition + offset ).y;
  gl_FragColor = vec4( mix( bottomColor, topColor, max( pow( max( h , 0.0), exponent ), 0.0 ) ), 1.0 );
}`;

const SkyDome = ({ topColor, bottomColor, ...other }) => {
  // SKYDOME
  var uniforms = {
    topColor: { value: new THREE.Color(0x0077ff) },
    bottomColor: { value: new THREE.Color(0xffffff) },
    offset: { value: 33 },
    exponent: { value: 0.6 },
  };
  // uniforms[ "topColor" ].value.copy( hemiLight.color );
  // scene.fog.color.copy( uniforms[ "bottomColor" ].value );

  return (
    <mesh {...other}>
      <sphereBufferGeometry attach="geometry" args={[1500, 32, 15]} />
      <shaderMaterial
        attach="material"
        uniforms={uniforms}
        uniforms-topColor-value={topColor}
        uniforms-bottomColor-value={bottomColor}
        vertexShader={vertexShader}
        fragmentShader={fragmentShader}
        side={THREE.DoubleSide}
      />
    </mesh>
  );
};

export default SkyDome;
