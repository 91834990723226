import React from 'react';

export const DownChevron = props => (
  <svg
    className={
      props.className ? props.className : 'inline fill-current h-3 w-3'
    }
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
  >
    <path d="M2,5 l16,0 l-8,8 Z " />
  </svg>
);

export const GridIcon = props => {
  const w = 4; // width
  const m = 3; // margin
  const ow = w + m;
  return (
    <svg
      className={
        props.className ? props.className : 'inline fill-current h-3 w-3'
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <g transform="translate(1, 1)">
        <rect x={ow * 0} y={ow * 0} width={w} height={w} />
        <rect x={ow * 1} y={ow * 0} width={w} height={w} />
        <rect x={ow * 2} y={ow * 0} width={w} height={w} />
        <rect x={ow * 0} y={ow * 1} width={w} height={w} />
        <rect x={ow * 1} y={ow * 1} width={w} height={w} />
        <rect x={ow * 2} y={ow * 1} width={w} height={w} />
        <rect x={ow * 0} y={ow * 2} width={w} height={w} />
        <rect x={ow * 1} y={ow * 2} width={w} height={w} />
        <rect x={ow * 2} y={ow * 2} width={w} height={w} />
      </g>
    </svg>
  );
};

export const PhylloIcon = props => {
  const maskId = `mask-${Math.round(Math.random() * 100000000)}`;
  return (
    <svg
      className={
        props.className ? props.className : 'inline fill-current h-3 w-3'
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <defs>
        <mask id={maskId}>
          <circle cx="10" cy="10" r={10} fill="white" />
        </mask>
      </defs>
      <g mask={`url(#${maskId})`}>
        <circle r="1.3" cx="9.05" cy="9.05"></circle>
        <circle r="1.3" cx="7.612130687747276" cy="10.367206073809971"></circle>
        <circle r="1.3" cx="9.29109535891029" cy="6.302842736952994"></circle>
        <circle
          r="1.3"
          cx="11.105001690003718"
          cy="11.730385803215999"
        ></circle>
        <circle r="1.3" cx="5.209617407269827" cy="8.370690393520686"></circle>
        <circle
          r="1.3"
          cx="12.729053681372996"
          cy="6.7096871983480915"
        ></circle>
        <circle r="1.3" cx="7.809998740024821" cy="13.662742879812399"></circle>
        <circle r="1.3" cx="6.672081538417161" cy="4.471462701903204"></circle>
        <circle
          r="1.3"
          cx="14.230763575081035"
          cy="10.942006548380206"
        ></circle>
        <circle r="1.3" cx="3.642578496593841" cy="11.282104989578379"></circle>
        <circle
          r="1.3"
          cx="11.663621506909099"
          cy="3.4648426505046235"
        ></circle>
        <circle r="1.3" cx="10.9855939541339" cy="15.220978532187608"></circle>
        <circle r="1.3" cx="3.2054998791741633" cy="5.662992716620351"></circle>
        <circle r="1.3" cx="15.916836433573707" cy="7.540345273069117"></circle>
        <circle r="1.3" cx="4.853722310061017" cy="15.018773203005821"></circle>
        <circle r="1.3" cx="8.0794464652678" cy="1.5603053576117771"></circle>
        <circle
          r="1.3"
          cx="15.014262164557138"
          cy="14.076686466493829"
        ></circle>
        <circle r="1.3" cx="1.0168098036223272" cy="9.382197635183747"></circle>
        <circle r="1.3" cx="14.914251601033673" cy="3.214286405265077"></circle>
        <circle r="1.3" cx="8.657379502425371" cy="17.54078024358682"></circle>
        <circle
          r="1.3"
          cx="3.4625901124161205"
          cy="2.3544155782986413"
        ></circle>
        <circle
          r="1.3"
          cx="17.906218675470033"
          cy="10.241591696956569"
        ></circle>
        <circle r="1.3" cx="1.542174583439877" cy="14.273749373242733"></circle>
        <circle
          r="1.3"
          cx="11.102561555352693"
          cy="-0.07384190248209688"
        ></circle>
        <circle r="1.3" cx="13.79957386878828" cy="17.33863969930673"></circle>
        <circle
          r="1.3"
          cx="-0.23875457766773334"
          cy="6.086633941637195"
        ></circle>
        <circle
          r="1.3"
          cx="18.076247173605996"
          cy="4.8796448639270835"
        ></circle>
        <circle r="1.3" cx="5.138237156161662" cy="18.396957336671964"></circle>
        <circle r="1.3" cx="5.55770380760237" cy="-0.6594730703867269"></circle>
        <circle r="1.3" cx="18.34543746160445" cy="13.935421414514884"></circle>
        <circle
          r="1.3"
          cx="-1.2778100435968858"
          cy="11.772377582808694"
        ></circle>
        <circle
          r="1.3"
          cx="14.921951317121298"
          cy="-0.08223344693714285"
        ></circle>
        <circle r="1.3" cx="10.918365826322997" cy="19.9214860593678"></circle>
        <circle
          r="1.3"
          cx="0.19352050138627153"
          cy="2.191044475240072"
        ></circle>
        <circle r="1.3" cx="20.38153409758231" cy="8.1112055627937"></circle>
        <circle r="1.3" cx="1.2158991942937938" cy="17.51843341864559"></circle>
        <circle
          r="1.3"
          cx="9.107075842812307"
          cy="-2.6498607832814534"
        ></circle>
        <circle
          r="1.3"
          cx="17.019489940284217"
          cy="17.835199490717823"
        ></circle>
        <circle r="1.3" cx="-2.919311775973301" cy="7.940686874887498"></circle>
        <circle
          r="1.3"
          cx="18.750298632634447"
          cy="1.6878191792302255"
        ></circle>
      </g>
    </svg>
  );
};

export const MapIcon = props => {
  return (
    <svg
      className={
        props.className ? props.className : 'inline fill-current h-3 w-3'
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
    >
      <g transform="translate(-1.000000, -2.000000)">
        <polygon points="2.31376496 5.15991211 0.991210938 9.06152344 2.31376496 13.072998 7.50929364 15.161377 9.70434134 17.074707 11.8080074 14.8178711 14.8165168 14.8178711 15.9312964 16.073581 16.8200645 17.074707 15.9629192 13.072998 17.5317549 11.1173096 16.8200645 9.56787109 19 6.52026367 17.5317549 5.8840332 14.8165168 8.59643555 12.1688113 6.52026367"></polygon>
      </g>
    </svg>
  );
};

export const LineIcon = props => {
  const w = 3; // width
  const m = 2; // margin
  const ow = w + m;
  return (
    <svg
      className={
        props.className ? props.className : 'inline fill-current h-3 w-3'
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <rect x={ow * 0} y={20 - 20} width={w} height={20} />
      <rect x={ow * 1} y={20 - 14} width={w} height={14} />
      <rect x={ow * 2} y={20 - 7} width={w} height={7} />
      <rect x={ow * 3} y={20 - 3} width={w} height={3} />
    </svg>
  );
};
