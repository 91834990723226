import { format, easeCubicInOut } from 'd3';
import teamsUnfiltered from './data/teams.json';

// 30 = MEM
// 34 = OKC
// 35 = brk
// 36 = new orleans pelicans
// 37 = charlotte hornets
export const teamMapper = {
  '3': 36, // CHH
  '31': 36, // NOH
  '33': 36, // NOK
  '32': 37, // bobcats
  '28': 30, // vancouver
  '25': 34, // Seattle
  '17': 35, // NJN
  '38': 12, // Buffalo Braves -> LAC
  '43': 12, // San Diego Clippers -> LAC
  '39': 23, // Kansas City Kings -> SAC
  '40': 27, // New Orleans Jazz -> UTA
  '41': 29, // Bullets -> WAS
  '42': 35, // New York Nets -> BRK

  // sketchy teams I assigned
  '44': 11, // Anderson Packers -> IND (Indiana)
  '58': 16, // Waterloo Hawks -> MIN   (Iowa)
  '46': 4, // Chicago Stags -> CHI
  '57': 29, // Washington Capitols -> WAS
  '54': 1, // St. Louis Bombers -> ATL
  '53': 15, // Sheboygan Red Skins -> MIL
  '49': 11, // Indianapolis Olympians -> IND

  // OK teams
  '50': 13, // Minneapolis Lakers -> LAL
  '51': 9, // Philadelphia Warriors -> GSW
  '52': 23, // Rochester Royals -> SAC
  '48': 8, // Fort Wayne Pistons -> DET
  '55': 20, // Syracuse Nationals -> PHI
  '47': 7, // Denver Nuggets -> DEN
  '56': 1, // Tri-Cities Blackhawks -> ATL
  '59': 1, // Milwaukee Hawks -> ATL
  '60': 1, // St. Louis Hawks -> ATL
  '61': 23, // Cincinnati Royals -> SAC
  '64': 9, // San Francisco Warriors -> GSW
  '62': 29, // Chicago Packers -> WAS
  '63': 29, // Chicago Zephyrs -> WAS
  '45': 29, // Baltimore Bullets -> WAS
  '65': 29, // Baltimore Bullets -> WAS
  '66': 10, // San Diego Rockets -> HOU
  '67': 23, // Kansas City-Omaha Kings -> SAC
  '68': 29, // Capital Bullets -> WAS
};

export const teams = { ...teamsUnfiltered };
for (const teamId of Object.keys(teamMapper)) {
  delete teams[teamId];
}

export const Stats = [
  // { value: 'num_seasons', label: '# Seasons' },

  {
    value: 'total_pts',
    labelShort: 'PTS',
    label: 'Points',
    formatter: d => `${format(',')(d)} Points`,
    formatterShort: format(','),
  },
  {
    value: 'total_ast',
    labelShort: 'AST',
    label: 'Assists',
    formatter: d => `${format(',')(d)} Assists`,
    formatterShort: format(','),
  },
  {
    value: 'total_reb',
    labelShort: 'REB',
    label: 'Rebounds',
    formatter: d => `${format(',')(d)} Rebounds`,
    formatterShort: format(','),
  },
  {
    value: 'total_stl',
    labelShort: 'STL',
    label: 'Steals',
    formatter: d => `${format(',')(d)} Steals`,
    formatterShort: format(','),
  },
  {
    value: 'total_blk',
    labelShort: 'BLK',
    label: 'Blocks',
    formatter: d => `${format(',')(d)} Blocks`,
    formatterShort: format(','),
  },
  {
    value: 'total_tov',
    labelShort: 'TOV',
    label: 'Turnovers',
    formatter: d => `${format(',')(d)} Turnovers`,
    formatterShort: format(','),
  },

  {
    value: 'total_fga',
    labelShort: 'FGA',
    label: 'FGA',
    formatter: d => `${format(',')(d)} FGA`,
    formatterShort: format(','),
  },
  {
    value: 'total_fg3a',
    labelShort: '3PA',
    label: '3PA',
    formatter: d => `${format(',')(d)} 3PT FGA`,
    formatterShort: format(','),
  },
  {
    value: 'total_min',
    labelShort: 'Min',
    label: 'Minutes',
    formatter: d => `${format(',')(d)} Minutes`,
    formatterShort: format(','),
  },
  // {
  //   value: 'total_gp',
  //   label: 'Games',
  //   labelShort: 'GP',
  //   formatter: d => `${format(',')(d)} games`,
  // },
];

export const animationSpringConfig = {
  duration: 1000,
  easing: easeCubicInOut,
};

export const Layouts = {
  players: { value: 'players', icon: 'grid' },
  'players-phyllo': { value: 'players-phyllo', icon: 'phyllo' },
  grid: { value: 'grid', icon: 'grid' },
  phyllo: { value: 'phyllo', icon: 'phyllo' },
  team: { value: 'team', icon: 'grid' },
  'team-phyllo': { value: 'team-phyllo', icon: 'phyllo' },
  'team-geo': { value: 'team-geo', icon: 'map' },
  'team-row': { value: 'team-row', icon: 'line' },
  'nba-logo': { value: 'nba-logo', icon: null },
};
