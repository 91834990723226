import React from 'react';

// const baseTitle = `NBA 3D Data Visualization: 1950–2020`;
const useTitle = title => {
  // React.useEffect(() => {
  //   document.title = title ? `${title} - ${baseTitle}` : baseTitle;
  // }, [title]);
};

export default useTitle;
