import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AppProvider } from './app-context';
import TrackPageView from './TrackPageView';
import Loader from './Loader';
import HomeView from './HomeView';
import { fetchData } from './api';
import { teams } from './constants';

// code-splitting by view
// const HomeView = React.lazy(() => import('./HomeView'));

function App() {
  const [data, setData] = React.useState(undefined);

  React.useEffect(() => {
    fetchData().then(results => {
      setData(results);
    });
  }, []);

  return (
    <AppProvider>
      <div className="App h-screen bg-gray-200 text-white w-full">
        <Router>
          <TrackPageView>
            <Suspense fallback={<Loader />}>
              <Switch>
                <Route path="debug" exact>
                  <pre>xx</pre>
                </Route>
                <Route
                  render={props =>
                    data ? <HomeView {...data} teams={teams} /> : <Loader />
                  }
                />
              </Switch>
            </Suspense>
          </TrackPageView>
        </Router>
      </div>
    </AppProvider>
  );
}

export default App;
