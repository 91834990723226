import * as React from 'react';
import cx from 'classnames';
import { Stats } from './constants';

const Tooltip = ({ item, statKey, x = 50, y = 50, itemsAreGrouped }) => {
  // use a saved ref so we can transition when item goes away and prevent flickering
  const savedItemRef = React.useRef({ item, x, y });
  if (item != null) {
    savedItemRef.current.item = item;
    savedItemRef.current.x = x;
    savedItemRef.current.y = y;
  }

  const stat = Stats.find(d => d.value === statKey);
  const visible = item != null;
  item = savedItemRef.current.item;
  x = savedItemRef.current.x;
  y = savedItemRef.current.y;

  return (
    <div
      className={cx(
        'pointer-events-none transition-opacity absolute top-0 left-0 z-50 bg-black text-white text-xs p-2 rounded text-center',
        { 'opacity-0': !visible, 'opacity-100': visible }
      )}
      style={{ transform: `translate(-50%, ${y + 25}px)`, left: `${x}px` }}
    >
      {item && (
        <>
          <div className="font-bold">
            {item.player_name}
            {itemsAreGrouped ? null : (
              <>
                <span className="opacity-50">-</span> {item.team.abbr}
              </>
            )}
          </div>
          <div>
            {stat.formatter(
              itemsAreGrouped ? item.group.totalStats[statKey] : item[statKey]
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Tooltip;
