import * as React from 'react';
import * as THREE from 'three';
import { useLoader } from 'react-three-fiber';
import { a, useSpring } from '@react-spring/three';
import { easeCubicInOut } from 'd3';

// const MAP_DIMENSIONS = { width: 914, height: 573 };
const MAP_DIMENSIONS = { width: 600, height: 400 };

const UsMapInner = ({ visible, width, ...other }) => {
  const [mapTexture] = useLoader(THREE.TextureLoader, [
    `/us_states_projected.png`,
  ]);
  const height = (width * MAP_DIMENSIONS.height) / MAP_DIMENSIONS.width;

  const { scale } = useSpring({
    scale: visible ? [1, 1, 1] : [1e-6, 1e-6, 1e-6],
    config: {
      duration: 1000,
      easing: easeCubicInOut,
    },
  });

  return (
    <a.mesh
      rotation={new THREE.Euler(-Math.PI * 0.5, 0, 0)}
      scale={scale}
      {...other}
      receiveShadow
    >
      <planeBufferGeometry attach="geometry" args={[width, height, 1]} />
      <meshStandardMaterial
        // depthTest={true}
        // depthWrite={false}
        // polygonOffset={true}
        // polygonOffsetFactor={-4}
        attach="material"
        color="#aaaaaa"
        roughness={1.0}
        metalness={0}
        transparent
        map={mapTexture}
      />
    </a.mesh>
  );
};

const UsMap = ({ width = 900, visible, ...other }) => {
  return (
    <React.Suspense fallback={null}>
      <UsMapInner width={width} visible={visible} {...other} />
    </React.Suspense>
  );
};
export default UsMap;
