import * as React from 'react';
import * as THREE from 'three';
import { useLoader } from 'react-three-fiber';
import { a, useSpring } from '@react-spring/three';
import { easeCubicInOut } from 'd3';

export const TeamLogo = ({ abbr, width, height, ...other }) => {
  const [logoTexture] = useLoader(THREE.TextureLoader, [
    `/logos/png/${abbr}.png`,
  ]);
  return (
    <mesh {...other}>
      <planeBufferGeometry attach="geometry" args={[width, height, 1]} />
      <meshStandardMaterial
        attach="material"
        color="#ffffff"
        transparent
        map={logoTexture}
      />
    </mesh>
  );
};

export const TeamLogoStand = ({
  abbr,
  logoWidth = 5,
  logoHeight = 5,
  standHeight = 5,
  position,
  visible = true,
  ...other
}) => {
  const logoTopRotation = new THREE.Euler(-Math.PI * 0.25, 0, 0);
  const {
    scale,
    animPosition,
    // animPositionLight,
    // animPositionLight2,
    // lightIntensity,
    // lightIntensity2,
  } = useSpring({
    animPosition: position,
    scale: visible ? [1, 1, 1] : [1e-6, 1e-6, 1e-6],
    // animPositionLight: [
    //   position[0],
    //   position[1] + standHeight * 1.2,
    //   position[2],
    // ],
    // animPositionLight2: [position[0], position[1] + 0.1, position[2] - 10],
    // lightIntensity: visible ? 1.2 : 0,
    // lightIntensity2: visible ? 0.4 : 0,
    config: {
      duration: 1000,
      easing: easeCubicInOut,
    },
  });

  return (
    <>
      <a.group scale={scale} position={animPosition} {...other}>
        <mesh castShadow position={[0, standHeight / 2, 0]}>
          <cylinderBufferGeometry
            attach="geometry"
            args={[0.1, 0.5, standHeight]}
          />
          <meshStandardMaterial attach="material" color="#ffffff" />
        </mesh>
        <group position={[0, standHeight, 0]} rotation={logoTopRotation}>
          <mesh castShadow>
            <boxBufferGeometry
              attach="geometry"
              args={[logoWidth * 1.2, logoHeight * 1.2, 0.2]}
            />
            <meshStandardMaterial attach="material" color="#ffffff" />
          </mesh>
          <React.Suspense fallback={null}>
            <TeamLogo
              position={[0, 0, 0.15]}
              abbr={abbr}
              width={logoWidth}
              height={logoHeight}
            />
          </React.Suspense>
        </group>
      </a.group>
      {/*
        lights cause a lot of lag
        <a.pointLight
        position={animPositionLight}
        distance={10}
        intensity={lightIntensity}
        decay={1}
        color="#ccccff"
      />
      <a.pointLight
        position={animPositionLight2}
        distance={60}
        intensity={lightIntensity2}
        decay={1}
        color="#9F7AEA"
      /> */}
    </>
  );
};

export const TeamLogos = ({ teams, visible, ...other }) => {
  console.log(visible, 'teams=', teams);
  return (
    <group {...other}>
      {Object.keys(teams).map(teamId => {
        const team = teams[teamId];
        return (
          <TeamLogoStand
            key={teamId}
            visible={visible}
            abbr={team.abbr}
            standHeight={3}
            logoWidth={3}
            logoHeight={3}
            position={[team.x || 0, team.y || 0, team.z || 0]}
          />
        );
      })}
    </group>
  );
};

export default TeamLogos;
