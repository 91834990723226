import { max, sum, ascending, descending, group } from 'd3-array';
import potpack from 'potpack';
import { scaleLinear, geoAlbers } from 'd3';
import { MIN_HEIGHT } from './heights';
const DEFAULT_MARGIN_FACTOR = 1.35;

function gridDimensions(numItems, aspectRatio = 1) {
  const numCols = Math.ceil(Math.sqrt(aspectRatio * numItems));
  const numRows = Math.ceil(numItems / numCols);

  return { numCols, numRows, numItems, aspectRatio };
}

function identityLayout(items) {
  const { numCols, numRows } = gridDimensions(items.length);
  const marginFactor = DEFAULT_MARGIN_FACTOR;
  const width = numCols * marginFactor;
  const height = numRows * marginFactor;

  const xScale = scaleLinear()
    .domain([0, 1])
    .range([-width / 2, width / 2]);

  const yScale = scaleLinear()
    .domain([0, 1])
    .range([-height / 2, height / 2]);

  for (const item of items) {
    item.x = xScale(item.bert_info[0]); // TODO xAccessor
    item.y = yScale(item.bert_info[1]); // TODO yAccessor
    item.z = Math.random();
  }

  return items;
}

function gridFit(
  items,
  {
    aspectRatio = 1,
    offsetX = 0,
    offsetY = 0,
    marginFactor = DEFAULT_MARGIN_FACTOR,
  } = {}
) {
  const numItems = items.length;
  let { numCols, numRows } = gridDimensions(numItems, aspectRatio);
  numCols *= 4; // need some empty spaces
  numRows *= 4;

  const grid = new Array(numCols);
  for (let col = 0; col < numCols; ++col) {
    grid[col] = new Array(numRows);
  }

  const xDomain = [0, 1];
  const yDomain = [0, 1];
  const colScale = scaleLinear()
    .domain([0, 1])
    .range([0, numCols - 1]);
  const rowScale = scaleLinear()
    .domain([0, 1])
    .range([0, numRows - 1]);

  for (const item of items) {
    const x = item.bert_info[0];
    const y = item.bert_info[1];
    const col = Math.round(colScale(x));
    const row = Math.round(rowScale(y));

    if (grid[col][row]) {
      grid[col][row].push(item);
    } else {
      grid[col][row] = [item];
    }

    item.z = -1000;
  }

  for (let col = 0; col < numCols; col++) {
    for (let row = 0; row < numRows; row++) {
      const cellItems = grid[col][row];
      if (!cellItems) {
        continue;
      }

      // STACK
      for (let i = 0; i < cellItems.length; ++i) {
        const item = cellItems[i];
        item.x = offsetX + (col - numCols / 2) * marginFactor;
        item.y = offsetY + (row - numRows / 2) * marginFactor;
        item.z = i * 0.3;
      }
    }
  }
}

function bertLayout(items) {
  // return identityLayout(items);
  return gridFit(items);
}

/**
 * Given a set of points, lay them out in a phyllotaxis layout.
 * Mutates the `points` passed in by updating the x and y values.
 *
 * @param {Object[]} points The array of points to update. Will get `x` and `y` set.
 * @param {Number} pointWidth The size in pixels of the point's width. Should also include margin.
 * @param {Number} offsetX The x offset to apply to all points
 * @param {Number} offsetY The y offset to apply to all points
 *
 * @return {Object[]} points with modified x and y
 */
function phyllotaxisLayout(
  items,
  { pointWidth = 2.2, offsetX = 0, offsetY = 0, iOffset = 0, plane = 'xz' } = {}
) {
  const { planeAttrs, nonPlaneAttr } = getPlaneAttrs(plane);
  // theta determines the spiral of the layout
  const theta = Math.PI * (3 - Math.sqrt(5));

  const pointRadius = pointWidth / 2;

  items.forEach((point, i) => {
    const index = (i + iOffset) % items.length;
    const phylloX = pointRadius * Math.sqrt(index) * Math.cos(index * theta);
    const phylloY = pointRadius * Math.sqrt(index) * Math.sin(index * theta);

    point[planeAttrs[0]] = offsetX + phylloX - pointRadius;
    point[planeAttrs[1]] = offsetY + phylloY - pointRadius;
    point[nonPlaneAttr] = 0;
  });

  return items;
}

function lineLayout(
  items,
  { pointWidth = 1, offsetX = 0, offsetY = 0, plane = 'xz', direction = 'row' }
) {
  const { planeAttrs, nonPlaneAttr } = getPlaneAttrs(plane);

  items.forEach((point, i) => {
    if (direction === 'row') {
      point[planeAttrs[0]] = offsetX + i * pointWidth;
      point[planeAttrs[1]] = offsetY;
    } else {
      point[planeAttrs[0]] = offsetX;
      point[planeAttrs[1]] = offsetY + i * pointWidth;
    }

    point[nonPlaneAttr] = 0;
  });

  return items;
}

const getPlaneAttrs = plane => {
  const planeAttrs = plane.split('');
  const nonPlaneAttr = plane.includes('x')
    ? plane.includes('y')
      ? 'z'
      : 'y'
    : 'x';

  return { planeAttrs, nonPlaneAttr };
};

function gridLayout(
  items,
  {
    aspectRatio = 1,
    offsetX = 0,
    offsetY = 0,
    scale = 1,
    marginFactor = DEFAULT_MARGIN_FACTOR,
    plane = 'xz',
  } = {}
) {
  const { planeAttrs, nonPlaneAttr } = getPlaneAttrs(plane);

  const numItems = items.length;
  const { numCols, numRows } = gridDimensions(numItems, aspectRatio);
  for (var i = 0; i < numItems; i++) {
    const col = i % numCols;
    // const row = numRows - Math.floor(i / numCols); // invert so we get first element in top left, otherwise it's at bottom left
    const row = Math.floor(i / numCols); // invert so we get first element in top left, otherwise it's at bottom left
    const item = items[i];

    item[planeAttrs[0]] =
      scale * (offsetX + (col - numCols / 2) * marginFactor); // e.g. item.x =
    item[planeAttrs[1]] =
      scale * (offsetY + (row - numRows / 2) * marginFactor); // e.g. item.y =
    item[nonPlaneAttr] = 0; // e.g. item.z =
  }

  return items;
}

function gridGroupBboxes(
  groups,
  { packGroups, subgroupLayout, aspectRatio, marginFactor, groupMargin }
) {
  // compute bounding boxes for each group
  const boxes = groups.map(group => {
    const { numCols, numRows, numItems } = gridDimensions(
      group[1].length,
      aspectRatio
    );
    return {
      w: numCols * marginFactor + groupMargin,
      h: numRows * marginFactor + groupMargin,
      numCols,
      numRows,
      numItems,
      group,
    };
  });

  let globalOffsetX = 0; // -packed.w /2
  let globalOffsetY = 0; // -packed.h / 2
  if (packGroups) {
    // use bin packing
    const packed = potpack(boxes); // assigns x and y to the box obj
    globalOffsetX = -packed.w / 2;
    globalOffsetY = -packed.h / 2;
  } else {
    // put into a grid
    const maxNumCols = max(boxes, d => d.numCols);
    const groupMarginNumCols = maxNumCols;

    gridLayout(boxes, {
      scale: maxNumCols + groupMarginNumCols,
      plane: 'xy',
    });
  }

  return { boxes, globalOffsetX, globalOffsetY };
}

function rowsGroupBboxes(groups, { aspectRatio, marginFactor, groupMargin }) {
  // compute bounding boxes for each group
  const boxes = groups.map((group, i) => {
    const numItems = group[1].length;
    const width = numItems * marginFactor;
    const height = 1 + groupMargin;
    return {
      x: -width / 2,
      y: i * height,
      w: numItems * marginFactor,
      h: 1 + groupMargin,
      numCols: numItems,
      numRows: 1,
      numItems,
      group,
    };
  });

  const maxWidth = max(boxes, d => d.w);
  const totalHeight = sum(boxes, d => d.h);

  return {
    boxes,
    globalOffsetX: -maxWidth * 0.5,
    globalOffsetY: -totalHeight / 2,
  };
}

function colsGroupBboxes(
  groups,
  { subgroupLayout, aspectRatio, marginFactor, groupMargin, packGroups }
) {
  // compute bounding boxes for each group
  const boxes = groups.map((group, i) => {
    const numItems = group[1].length;
    const width = 1 + groupMargin;
    const height = numItems * marginFactor;
    return {
      x: i * width,
      y: -height / 2,
      w: 1 + groupMargin,
      h: numItems * marginFactor,
      numCols: 1,
      numRows: numItems,
      numItems,
      group,
    };
  });

  const maxHeight = max(boxes, d => d.h);
  const totalWidth = sum(boxes, d => d.w);

  return {
    boxes,
    globalOffsetX: -totalWidth * 0.5,
    globalOffsetY: -maxHeight * 0,
  };
}

function groupLayout(
  items,
  {
    groupByAccessor,
    groupComparator,
    computeGroupBboxes = gridGroupBboxes,
    groupMargin = 10,
    keyMapper = d => d,
    collectFiltered,
    packGroups,
    subgroupLayout = gridLayout,
    subgroupLayoutOptions = {},
  } = {}
) {
  // split the items into groups
  const groupMap = group(items, groupByAccessor);
  const groups = Array.from(groupMap);

  // sort the groups by the largest size by default otherwise use provided groupComparator
  if (groupComparator) {
    groups.sort(groupComparator);
  } else {
    groups.sort((a, b) => descending(a[1].length, b[1].length));
  }
  items.layoutMeta = {
    groupKeys: groups.map(d => keyMapper(d[0])),
    groups: [],
  };

  const marginFactor = DEFAULT_MARGIN_FACTOR;
  const aspectRatio = 1;

  // compute bounding boxes for each group
  const groupBboxes = computeGroupBboxes(groups, {
    marginFactor,
    groupMargin,
    aspectRatio,
    subgroupLayout,
    packGroups,
  });
  const { boxes, globalOffsetX = 0, globalOffsetY = 0 } = groupBboxes;

  for (const box of boxes) {
    const boxItems = box.group[1];

    const boxOffsetX = box.x + box.w / 2 + globalOffsetX;
    const boxOffsetY = box.y + box.h / 2 + globalOffsetY;
    subgroupLayout(boxItems, {
      aspectRatio,
      marginFactor,
      offsetX: boxOffsetX,
      offsetY: boxOffsetY,
      pointWidth: 2.2,
      ...subgroupLayoutOptions,
    });

    // treat the filtered as its own subgroup
    if (items.filteredLength !== items.length && collectFiltered) {
      // some active filter
      const boxFiltered = boxItems.filter(d => d.filteredIn);
      if (boxFiltered.length) {
        subgroupLayout(boxFiltered, {
          aspectRatio,
          marginFactor,
          offsetX: boxOffsetX,
          offsetY: boxOffsetY,
        });
      }
    }

    items.layoutMeta.groups.push({
      key: box.group[0],
      label: keyMapper(box.group[0]) || '<unknown>',
      midX: box.x + box.w / 2 + globalOffsetX,
      topY: box.y + box.h + globalOffsetY,
    });
  }

  return groupBboxes;
}

function groupGeoLayout(
  items,
  {
    groupByAccessor,
    groupComparator,
    keyMapper = d => d,
    collectFiltered,
    teams,
  } = {}
) {
  // split the items into groups
  const groupMap = group(items, groupByAccessor);
  const groups = Array.from(groupMap);

  // sort the groups by the largest size by default otherwise use provided groupComparator
  if (groupComparator) {
    groups.sort(groupComparator);
  } else {
    groups.sort((a, b) => descending(a[1].length, b[1].length));
  }
  items.layoutMeta = {
    groupKeys: groups.map(d => keyMapper(d[0])),
    groups: [],
  };

  const marginFactor = DEFAULT_MARGIN_FACTOR;
  const groupMargin = 0;
  const aspectRatio = 1;

  // compute bounding boxes for each group
  const boxes = groups.map(group => {
    const { numCols, numRows } = gridDimensions(group[1].length, aspectRatio);
    return {
      w: numCols * marginFactor + groupMargin,
      h: numRows * marginFactor + groupMargin,
      numCols,
      numRows,
      group,
    };
  });

  const globalOffsetX = 0; // -packed.w /2
  const globalOffsetY = 0; // -packed.h / 2

  const teamPointGeoJson = {
    type: 'FeatureCollection',
    features: Object.keys(teams).map(teamId => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: teams[teamId].lng_lat,
      },
    })),
  };

  const projectionWidth = 900;
  const projectionHeight = 600;
  const projection = geoAlbers().fitExtent(
    [
      [-projectionWidth / 2, -projectionHeight / 2],
      [projectionWidth / 2, projectionHeight / 2],
    ],
    teamPointGeoJson
  );
  projection.scale(projection.scale() * 0.8);
  // .center([-98.5795, 39.8283])
  // .translate([-1000, -600]);
  // .translate([-200, -100]);
  // .scale(1280)
  // .translate([-120, -150]);

  for (const box of boxes) {
    const boxItems = box.group[1];
    const team = boxItems[0].team;
    const projected = projection(team.lng_lat);
    box.x = projected[0] - box.w / 2; // top left corner of box. we want proj to be centered
    box.y = projected[1] - box.h / 2;

    // handle special overlapping cases
    if (team.abbr === 'LAC' || team.abbr === 'BRK') {
      box.x += box.w * 1.15;
    } else if (team.abbr === 'DET') {
      box.x -= box.w * 0.4;
    } else if (team.abbr === 'SAC') {
      box.x += box.w * 0.5;
    } else if (team.abbr === 'MIL') {
      box.y -= box.h * 0.4;
    } else if (team.abbr === 'PHI') {
      box.y += box.h * 0.65;
      box.x += box.w * 0.4;
    }

    const boxOffsetX = box.x + box.w / 2 + globalOffsetX;
    const boxOffsetY = box.y + box.h / 2 + globalOffsetY;
    gridLayout(boxItems, {
      aspectRatio,
      marginFactor,
      offsetX: boxOffsetX,
      offsetY: boxOffsetY,
    });

    // boxItems[0].x = box.x;
    // boxItems[0].z = box.y;

    // boxItems[1].x = box.x + box.w;
    // boxItems[1].z = box.y + box.h;

    // boxItems[2].x = box.x + box.w;
    // boxItems[2].z = box.y;

    // boxItems[3].x = box.x;
    // boxItems[3].z = box.y + box.h;

    // for (const item of boxItems) {
    //   item.x = box.x;
    //   item.y = 0;
    //   item.z = box.y;
    // }

    // treat the filtered as its own subgroup
    if (items.filteredLength !== items.length && collectFiltered) {
      // some active filter
      const boxFiltered = boxItems.filter(d => d.filteredIn);
      if (boxFiltered.length) {
        gridLayout(boxFiltered, {
          aspectRatio,
          marginFactor,
          offsetX: boxOffsetX,
          offsetY: boxOffsetY,
        });
      }
    }

    items.layoutMeta.groups.push({
      key: box.group[0],
      label: keyMapper(box.group[0]) || '<unknown>',
      midX: box.x + box.w / 2 + globalOffsetX,
      topY: box.y + box.h + globalOffsetY,
    });
  }

  return { boxes, globalOffsetX, globalOffsetY };
}

function playersLayout(players, { layoutFunc = gridLayout } = {}) {
  layoutFunc(players);
  for (const player of players) {
    let height = 0;
    const heightOffset = (player.items.length - 1) * MIN_HEIGHT;
    for (let i = 0; i < player.items.length; ++i) {
      const playerTeam = player.items[i];
      playerTeam.x = player.x;
      playerTeam.y = height - heightOffset;
      playerTeam.z = player.z;
      height += playerTeam.height;
    }
  }
}

function positionLogos(
  teams,
  groupBboxes,
  { offsetYFactor = 0, offsetXFactor = 0, offsetX = 0, offsetY = 0 } = {}
) {
  const { boxes, globalOffsetX, globalOffsetY } = groupBboxes;
  for (const box of boxes) {
    const teamId = box.group[0];
    teams[teamId].x =
      box.x + globalOffsetX + box.w / 2 + box.w * offsetXFactor + offsetX;
    teams[teamId].y = 0;
    teams[teamId].z =
      box.y + globalOffsetY + box.h + box.h * offsetYFactor + offsetY;
  }
}

function nbaLogoLayout(items, nbaLogoImage) {
  const canvas = document.createElement('canvas');
  const img = nbaLogoImage;
  canvas.width = img.width;
  canvas.height = img.height;
  canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height);

  /*
  canvas.style.position = 'absolute';
  canvas.style.top = '0';
  canvas.style.left = '0';
  canvas.style.zIndex = '100000';
  document.body.appendChild(canvas);
  */

  const { numCols, numRows } = gridDimensions(
    items.length,
    img.width / img.height
  );
  const xIncrement = img.width / numCols;
  const yIncrement = img.height / numRows;
  const scale = 1;
  const offsetX = 0;
  const offsetY = 0;
  const marginFactor = DEFAULT_MARGIN_FACTOR;

  for (let i = 0; i < items.length; ++i) {
    const item = items[i];
    const index = item.instanceId;
    const col = index % numCols;
    // const row = numRows - Math.floor(index / numCols); // invert so we get first element in top left, otherwise it's at bottom left
    const row = Math.floor(index / numCols); // invert so we get first element in top left, otherwise it's at bottom left

    const x = Math.round(xIncrement * col);
    const y = Math.round(yIncrement * row);
    const rgba = canvas.getContext('2d').getImageData(x, y, 1, 1).data;

    item.x = scale * (offsetX + (col - numCols / 2) * marginFactor);
    // item.z = scale * (offsetY + (row - numRows / 2) * marginFactor);
    item.y = scale * (offsetY + (numRows - row) * marginFactor);
    item.z = numCols / 2;
    item.color = rgba;
    item.height = 1;
  }
  items.useItemColors = true;
}

export function applyLayout(items, layout, layoutOptions = {}) {
  items.layoutMeta = {};
  let groupBboxes;
  const { teams } = layoutOptions;
  items.useItemColors = false;

  switch (layout) {
    case 'team':
      groupBboxes = groupLayout(items, {
        subgroupLayout: gridLayout,
        groupMargin: 10,
        groupByAccessor: d => d.team_id,
        groupComparator: (a, b) =>
          ascending(a[1][0].team.full_name, b[1][0].team.full_name),
        ...layoutOptions,
      });
      positionLogos(teams, groupBboxes);
      break;
    case 'team-phyllo':
      groupBboxes = groupLayout(items, {
        subgroupLayout: phyllotaxisLayout,
        groupMargin: 10,
        groupByAccessor: d => d.team_id,
        groupComparator: (a, b) =>
          ascending(a[1][0].team.full_name, b[1][0].team.full_name),
        ...layoutOptions,
      });
      positionLogos(teams, groupBboxes, { offsetYFactor: 0.15 });
      break;
    case 'team-row':
      groupBboxes = groupLayout(items, {
        subgroupLayout: lineLayout,
        subgroupLayoutOptions: { direction: 'row' },
        computeGroupBboxes: rowsGroupBboxes,
        groupByAccessor: d => d.team_id,
        groupComparator: (a, b) =>
          ascending(a[1][0].team.full_name, b[1][0].team.full_name),
        ...layoutOptions,
      });
      positionLogos(teams, groupBboxes, {
        offsetYFactor: -0.5,
        offsetX: -5,
      });

      break;
    case 'team-col':
      groupBboxes = groupLayout(items, {
        subgroupLayout: lineLayout,
        subgroupLayoutOptions: { direction: 'col' },
        computeGroupBboxes: colsGroupBboxes,
        groupByAccessor: d => d.team_id,
        groupComparator: (a, b) =>
          ascending(a[1][0].team.full_name, b[1][0].team.full_name),
        ...layoutOptions,
      });
      positionLogos(teams, groupBboxes);

      break;
    case 'team-geo':
      groupBboxes = groupGeoLayout(items, {
        groupByAccessor: d => d.team_id,
        ...layoutOptions,
      });
      positionLogos(teams, groupBboxes, { offsetY: 2 });
      break;
    case 'players':
      playersLayout(layoutOptions.players, { layoutFunc: gridLayout });
      break;
    case 'players-phyllo':
      playersLayout(layoutOptions.players, { layoutFunc: phyllotaxisLayout });
      break;
    case 'phyllo':
      phyllotaxisLayout(items);
      break;
    case 'nba-logo':
      nbaLogoLayout(items, layoutOptions.nbaLogoImage);
      break;
    case 'grid':
    default:
      gridLayout(items);
  }

  items.layoutTimestamp = performance.now();
  return items;
}
