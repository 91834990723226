import * as React from 'react';
import * as THREE from 'three';
import SkyDome from './SkyDome';

const SkyEnv = ({
  skyColor = '#3385ff',
  fogColor = '#ffffff',
  groundColor = '#718096',
  dirLightColor = '#fff5e5',
  includeGround = true,
  includeSkyDome = true,
  includeFog = true,
}) => {
  const skyTopColorVec = React.useMemo(() => new THREE.Color(skyColor), [
    skyColor,
  ]);
  const skyBottomColorVec = React.useMemo(() => new THREE.Color(fogColor), [
    fogColor,
  ]);

  return (
    <>
      <hemisphereLight
        skyColor={skyColor}
        groundColor={groundColor}
        intensity={0.6}
        position={[0, 50, 0]}
      />
      <directionalLight
        color={dirLightColor}
        intensity={0.5}
        position={[-1 * 100, 1.75 * 100, 1 * 100]}
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
        shadow-camera-near={0.01}
        shadow-camera-far={30000}
        shadow-camera-left={-400}
        shadow-camera-bottom={-400}
        shadow-camera-top={400}
        shadow-camera-right={400}
        shadow-bias={-0.00001}
        castShadow
      />
      {includeFog && <fog attach="fog" color={fogColor} near={1} far={1500} />}
      {includeGround && (
        <mesh
          position={[0, -0.45, 0]} // slightly below 0 to prevent intersecting artifacts
          rotation={new THREE.Euler(-Math.PI * 0.5, 0, 0)} /* FLOOR */
          receiveShadow
        >
          <planeBufferGeometry attach="geometry" args={[10000, 10000, 1, 1]} />
          <meshStandardMaterial
            attach="material"
            color={groundColor}
            roughness={1}
            metalness={0}
            side={THREE.DoubleSide}
          />
        </mesh>
      )}
      {includeSkyDome && (
        <SkyDome topColor={skyTopColorVec} bottomColor={skyBottomColorVec} />
      )}
    </>
  );
};

export default SkyEnv;
