import * as React from 'react';
import cx from 'classnames';
import CloseIcon from './CloseIcon';
import { Stats } from './constants';
import { scaleLinear, scaleLog } from 'd3';

const StatHistogram = ({ statValue, stat, bins, width, height }) => {
  const value = statValue;
  const statBins = bins.stats[stat.value];
  const yExtent = bins.yExtentOverall;
  const padding = { top: 5, left: 0, right: 5, bottom: 5 };
  const plotAreaWidth = width - padding.left - padding.right;
  const plotAreaHeight = height - padding.top - padding.bottom;

  const xScale = scaleLinear()
    .domain(statBins.xExtent)
    .range([0, plotAreaWidth]);

  const yScale = scaleLog()
    .domain([1, yExtent[1] + 1])
    .range([plotAreaHeight, 0]);

  return (
    <svg width={width} height={height}>
      <g transform={`translate(${padding.left} ${padding.top})`}>
        <g>
          {statBins.bins.map((bin, i) => {
            const x = xScale(bin.x0);
            const y = yScale(bin.n + 1);
            const barWidth = Math.floor(xScale(bin.x1) - xScale(bin.x0));
            const barHeight = bin.n > 0 ? Math.max(1, plotAreaHeight - y) : 0;
            const playerBin = bin.x0 <= value && value <= bin.x1;
            return (
              <rect
                key={i}
                x={x}
                y={y}
                height={barHeight}
                width={barWidth}
                className={cx('fill-current', {
                  'text-gray-800': !playerBin,
                  'text-yellow-400': playerBin,
                })}
              />
            );
          })}
        </g>
      </g>
    </svg>
  );
};

const StatBlock = ({ statValue, stat, className, bins }) => {
  const value = statValue;
  const width = 135;
  const height = 50;

  return (
    <div className={cx(className, 'bg-black rounded flex flex-col')}>
      <StatHistogram
        width={width}
        height={height}
        statValue={statValue}
        stat={stat}
        bins={bins}
      />
      <div className="text-">
        <strong>{stat.formatterShort(value)}</strong> {stat.label}
      </div>
    </div>
  );
};

const StatBlocks = ({ showTotal, item, className, bins }) => {
  return (
    <div className={cx(className)}>
      <div className="flex flex-wrap">
        {Stats.map(stat => {
          return (
            <StatBlock
              statValue={
                showTotal ? item.totalStats[stat.value] : item[stat.value]
              }
              stat={stat}
              bins={bins}
              key={stat.value}
              className="mr-3 mb-3"
            />
          );
        })}
      </div>
      <p className="text-gray-600 text-sm">
        {showTotal
          ? `Log y-axis shows number of Players, x-axis is the stat value totaled across all teams for a given player.`
          : `Log y-axis shows number of Player × Team combinations, x-axis is the stat value for a single player-team total.`}
      </p>
    </div>
  );
};

export const seasonFormat = d => `${d - 1}-${('' + d).slice(2)}`;

function seasonString(seasons) {
  const chunks = [[seasons[0]]];
  let chunk = chunks[0];
  for (let i = 1; i < seasons.length; ++i) {
    // are we in a new chunk?
    if (seasons[i - 1] + 1 !== seasons[i]) {
      chunk = [];
      chunks.push(chunk);
    }
    chunk.push(seasons[i]);
  }

  return chunks
    .map(chunk =>
      chunk.length === 1
        ? seasonFormat(chunk[0])
        : `${seasonFormat(chunk[0])} through ${seasonFormat(
            chunk[chunk.length - 1]
          )}`
    )
    .join(', ');
}

const Button = ({ children, className, active, disabled, ...other }) => (
  <button
    className={cx(
      'border border-gray-600 text-left py-1 px-2 mr-2 rounded text-lg text-white hover:text-gray-400  focus:outline-none outline-none focus:shadow-outline',
      {
        'text-yellow-400 hover:text-yellow-400': active,
        'opacity-50 cursor-default hover:text-gray-600 text-gray-600': disabled,
      },
      className
    )}
    disabled={disabled}
    {...other}
  >
    {children}
  </button>
);

const TeamButtons = ({ showTotal, item, onClick }) => {
  const teamItems = item.group.items.slice().reverse();
  return (
    <div>
      <Button active={showTotal} onClick={() => onClick('total')}>
        Total
      </Button>
      {teamItems.map((teamItem, i) => {
        const team = teamItem.team;
        return (
          <Button
            key={team.id}
            active={!showTotal && team.id === item.team.id}
            onClick={() => onClick(teamItem)}
          >
            {team.abbr}
          </Button>
        );
      })}
    </div>
  );
};

const SeasonSummary = ({ item, showTotal }) => {
  // TODO: add item.group.seasons
  const seasons = showTotal ? item.group.seasons : item.seasons;

  return (
    <div className="text-lg text-gray-400">
      <span className="font-semibold">
        {showTotal
          ? item.group.items.length > 1
            ? 'Multiple Teams'
            : 'Total'
          : item.team.full_name}
      </span>{' '}
      <span className="text-gray-500">
        {seasonString(seasons)} ({seasons.length} seasons)
      </span>
    </div>
  );
};

const DetailsPanel = ({
  item,
  onSelectItem,
  itemsAreGrouped,
  className,
  onClose,
  playerTeamBins,
  totalBins,
  ...other
}) => {
  const [showTotal, setShowTotal] = React.useState(itemsAreGrouped);
  const handleTeamClick = item => {
    if (item === 'total') {
      setShowTotal(true);
    } else {
      onSelectItem(item);
      setShowTotal(false);
    }
  };

  if (!item) {
    return null;
  }
  console.log('got item', item);

  return (
    <div
      className={cx('bg-black p-4 border-l border-gray-900', className)}
      {...other}
    >
      <button
        className="absolute top-0 leading-none right-0 p-2 pr-4 pt-4 hover:text-gray-400 text-gray-700 focus:outline-none"
        onClick={onClose}
      >
        <CloseIcon />
      </button>

      <header className="mb-4">
        <div className="flex items-end mb-1">
          <h1 className="text-2xl font-bold whitespace-no-wrap">
            {item.player_name}
          </h1>
          <div className="flex-grow flex items-center pl-6">
            <TeamButtons
              showTotal={showTotal}
              item={item}
              onClick={handleTeamClick}
            />
          </div>
        </div>
        <SeasonSummary item={item} showTotal={showTotal} />
      </header>
      <StatBlocks
        item={showTotal ? item.group : item}
        showTotal={showTotal}
        bins={showTotal ? totalBins : playerTeamBins}
      />
    </div>
  );
};

export default React.memo(DetailsPanel);
