import * as React from 'react';
import cx from 'classnames';
import CloseIcon from './CloseIcon';

const HelpPanel = ({ className, onClose }) => {
  return (
    <div
      className={cx(
        'absolute inset-x-0 z-20 bg-black p-4 border rounded-lg border-gray-900 max-w-4xl mx-auto mt-5',
        className
      )}
    >
      <button
        className="absolute top-0 leading-none right-0 p-2 pr-4 pt-4 hover:text-gray-400 text-gray-700 focus:outline-none"
        onClick={onClose}
      >
        <CloseIcon />
      </button>
      <header className="mb-4">
        <h1 className="text-2xl font-bold">
          NBA 3D Data Visualization: 1950–2020
        </h1>
        <h2 className="text-lg text-gray-400">
          By{' '}
          <a className="hover:underline" href="https://peterbeshai.com">
            Peter Beshai
          </a>
        </h2>
      </header>
      <section className="mb-4">
        <p className="mb-2">
          This experimental 3D data visualization shows totals for several
          counting stats from the 1949-50 NBA season through the year end of
          2019. I wanted to see how much players contributed to their teams and
          the NBA overall, a little glimpse into their legacies. Older teams
          (e.g. Minneapolis Lakers) have been mapped to newer teams (e.g. Los
          Angeles Lakers).
        </p>
        <p className="mb-2">
          When using a <strong>Player</strong> layout, each cylinder represents
          the total for the selected stat across the player's career. Each
          player will only show up once.
        </p>
        <p className="mb-2">
          When using a <strong>Player × Team</strong> or <strong>Team</strong>{' '}
          layout, each cylinder represents the total for the selected stat for a
          player on a given team. Players will show up multiple times if they
          played on multiple teams.
        </p>
      </section>
      <section>
        <h3 className="text-xl font-semibold">Controls</h3>
        <table>
          <tbody>
            <tr>
              <th className="text-left pr-3">Zoom</th>
              <td>mouse wheel, or two finger swipe</td>
            </tr>
            <tr>
              <th className="text-left pr-3">Rotate</th>
              <td>click and drag</td>
            </tr>
            <tr>
              <th className="text-left pr-3">Pan</th>
              <td>
                right-click and drag, cmd+click and drag, or two finger tap and
                drag
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  );
};

export default HelpPanel;
