import React, { useRef, useMemo } from 'react';
import * as THREE from 'three';
import { Canvas, extend } from 'react-three-fiber';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { OrbitControls } from './OrbitControls';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls';
import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { SSAOPass } from 'three/examples/jsm/postprocessing/SSAOPass';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import InstancedPlayers from './InstancedPlayers';
import Controls from './Controls';
import SkyDome from './SkyDome';
import { applyLayout } from './layouts';
import { applyHeights } from './heights';
import SkyEnv from './SkyEnv';
import TeamLogos from './TeamLogos';
import UsMap from './UsMap';
import { ascending, descending } from 'd3-array';

extend({
  OrbitControls,
  TrackballControls,
  EffectComposer,
  RenderPass,
  ShaderPass,
  UnrealBloomPass,
  SSAOPass,
  BokehPass,
});

const ThreeVis = (
  {
    layoutKey,
    statKey,
    playerTeams,
    teams,
    players,
    selectedInstance,
    onSelectInstance,
    hoverInstance,
    onHoverInstance,
    nbaLogoImage,
    itemsAreGrouped,
    hideFilteredOut,
  },
  ref
) => {
  const controlsRef = useRef();
  React.useImperativeHandle(ref, () => ({
    resetCamera: () => {
      return controlsRef.current.resetCamera();
    },
  }));

  const filterTimestamp = playerTeams.filterTimestamp;
  const items = playerTeams || []; //.filter(d => d.team_id === 2);
  items.sort((a, b) => descending(a[statKey], b[statKey]));
  const heightTimestamp = useMemo(() => {
    applyHeights(items, statKey, {
      hideFilteredOut,
      extent: playerTeams.extents.overall,
    });
    return items.heightTimestamp;
    // recompute on filter
  }, [statKey, items, filterTimestamp, layoutKey, hideFilteredOut]);

  const layoutTimestamp = useMemo(() => {
    applyLayout(items, layoutKey, {
      teams,
      players,
      nbaLogoImage,
      packGroups: false,
    });
    return items.layoutTimestamp;
    // need heightTimestamp here since we re-order after changing the stat key
  }, [layoutKey, items, filterTimestamp, heightTimestamp, nbaLogoImage]);

  if (!playerTeams || !playerTeams.length) return null;
  const showTeams = ['team', 'team-row', 'team-phyllo', 'team-geo'].includes(
    layoutKey
  );

  const showMap = layoutKey === 'team-geo';

  return (
    <Canvas
      shadowMap
      camera={{
        fov: 75,
        orthographic: false,
        // zoom: 1,
        position: [0, 150, 150],
        near: 0.1,
        far: 3000,
      }}
    >
      <SkyEnv
        skyColor="#000000"
        fogColor="#434190"
        groundColor="#231f3e"
        dirLightColor="#ffffff"
        includeGround
        includeSkyDome
        includeFog //={false}
      />
      <directionalLight
        color={'#3182CE'}
        intensity={0.2}
        position={[1 * 30, 1.75 * 30, 1 * 30]}
      />
      <UsMap visible={showMap} position={[0, 0.1, 0]} />
      <TeamLogos teams={teams} position={[0, -0.5, 0]} visible={showTeams} />
      <InstancedPlayers
        players={items}
        selectedInstance={selectedInstance}
        onSelectInstance={onSelectInstance}
        hoverInstance={hoverInstance}
        onHoverInstance={onHoverInstance}
        itemsAreGrouped={itemsAreGrouped}
      />
      {/*
      <mesh position={[0, 2, 0]} receiveShadow castShadow>
        <boxBufferGeometry attach="geometry" args={[2, 2, 2]} />
        <meshPhongMaterial attach="material" color="#ff0000" />
      </mesh>
      */}
      <Controls
        ref={controlsRef}
        useTrackball={false}
        /* lookAt={selectedInstance && selectedInstance.point} */
      />
    </Canvas>
  );
};

export default React.forwardRef(ThreeVis);
