import React from 'react';

const AppStateContext = React.createContext();
const AppDispatchContext = React.createContext();

function appReducer(state, action) {
  switch (action.type) {
    default:
  }
  return state;
}
export function AppProvider({ children }) {
  const [state, dispatch] = React.useReducer(appReducer, {
    topTerms: undefined,
  });

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
}

export function useAppState() {
  const context = React.useContext(AppStateContext);
  if (process.env.NODE_ENV === 'development' && context === undefined) {
    throw new Error('useAppState must be used within a AppProvider');
  }
  return context;
}

export function useAppDispatch() {
  const context = React.useContext(AppDispatchContext);
  if (process.env.NODE_ENV === 'development' && context === undefined) {
    throw new Error('useAppDispatch must be used within a AppProvider');
  }
  return context;
}

export function useAppContext() {
  return [useAppState(), useAppDispatch()];
}
