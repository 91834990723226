import React, { useEffect } from 'react';
import * as THREE from 'three';

const Colors = {
  default: [1, 1, 1],
  filteredIn: [0.99, 0.99, 0.0],
  filteredOut: [0.1, 0.1, 0.1],
  hover: [0.0, 1.0, 0.0],
  selected: [255, 100, 255].map(d => d / 255),
};

function initializeColorsArray(items) {
  const numItems = items.length;

  var colors = new Float32Array(numItems * 3); // rgb

  for (var i = 0, i3 = 0, l = numItems; i < l; i++, i3 += 3) {
    const item = items[i];
    if (items.useItemColors) {
      colors[i3 + 0] = item.color[0] / 255;
      colors[i3 + 1] = item.color[1] / 255;
      colors[i3 + 2] = item.color[2] / 255;
    } else {
      colors[i3 + 0] = Colors.default[0];
      colors[i3 + 1] = Colors.default[1];
      colors[i3 + 2] = Colors.default[2];
    }
  }
  return colors;
}

function updateItemColor(item, colors, hasFilters, useItemColors) {
  const i3 = item.instanceId * 3;
  if (item.isSelected) {
    colors[i3 + 0] = Colors.selected[0];
    colors[i3 + 1] = Colors.selected[1];
    colors[i3 + 2] = Colors.selected[2];
  } else if (item.isHovered) {
    colors[i3 + 0] = Colors.hover[0];
    colors[i3 + 1] = Colors.hover[1];
    colors[i3 + 2] = Colors.hover[2];
  } else if (!hasFilters) {
    if (useItemColors) {
      colors[i3 + 0] = item.color[0] / 255;
      colors[i3 + 1] = item.color[1] / 255;
      colors[i3 + 2] = item.color[2] / 255;
    } else {
      colors[i3 + 0] = Colors.default[0];
      colors[i3 + 1] = Colors.default[1];
      colors[i3 + 2] = Colors.default[2];
    }
  } else if (item.filteredIn) {
    colors[i3 + 0] = Colors.filteredIn[0];
    colors[i3 + 1] = Colors.filteredIn[1];
    colors[i3 + 2] = Colors.filteredIn[2];
  } else {
    // filtered out
    colors[i3 + 0] = Colors.filteredOut[0];
    colors[i3 + 1] = Colors.filteredOut[1];
    colors[i3 + 2] = Colors.filteredOut[2];
  }
}

function updateColorsArray(items, colors, prevColors) {
  const numItems = items.length;

  for (var i = 0, l = numItems; i < l; i++) {
    const item = items[i];
    const i3 = item.instanceId * 3;
    prevColors[i3 + 0] = colors[i3 + 0];
    prevColors[i3 + 1] = colors[i3 + 1];
    prevColors[i3 + 2] = colors[i3 + 2];

    updateItemColor(item, colors, items.hasFilters, items.useItemColors);
  }
}

function useColors(
  meshRef,
  items,
  hoverInstance,
  selectedInstance,
  itemsAreGrouped
) {
  const numItems = items.length;

  useEffect(() => {
    const mesh = meshRef.current;
    const { attributes } = mesh.geometry;

    // add instanceColor and prevInstanceColor attributes
    if (attributes.instanceColor == null) {
      mesh.geometry.setAttribute(
        'instanceColor',
        new THREE.InstancedBufferAttribute(initializeColorsArray(items), 3)
      );
      mesh.geometry.setAttribute(
        'prevInstanceColor',
        new THREE.InstancedBufferAttribute(
          new Float32Array(numItems * 3).fill(0),
          3
        )
      );
    }

    const colors = attributes.instanceColor.array;
    const prevColors = attributes.prevInstanceColor.array;

    // set color values
    updateColorsArray(items, colors, prevColors);

    attributes.instanceColor.needsUpdate = true;
    attributes.prevInstanceColor.needsUpdate = true;

    // start a color animation
    const material = meshRef.current.material;
    if (material.uniforms) {
      material.uniforms.animateColorStartTime.value = performance.now() / 1000;
    }
  }, [numItems, items, items.filterTimestamp, items.useItemColors]);

  // handle selecting
  useEffect(() => {
    const instanceColor = meshRef.current.geometry.attributes.instanceColor;
    let itemsToUpdate;
    if (selectedInstance != null) {
      itemsToUpdate = selectedInstance.item.group.items;

      for (const item of itemsToUpdate) {
        // item.isSelected = true;

        updateItemColor(
          item,
          instanceColor.array,
          items.hasFilters,
          items.useItemColors
        );
      }
      instanceColor.needsUpdate = true;
    }

    return () => {
      if (selectedInstance != null) {
        for (const item of itemsToUpdate) {
          // item.isSelected = false;
          updateItemColor(
            item,
            instanceColor.array,
            items.hasFilters,
            items.useItemColors
          );
        }
        instanceColor.needsUpdate = true;
      }
    };
  }, [selectedInstance, items, itemsAreGrouped]);

  // handle hovering
  useEffect(() => {
    const instanceColor = meshRef.current.geometry.attributes.instanceColor;
    let itemsToUpdate;
    if (hoverInstance != null) {
      itemsToUpdate = itemsAreGrouped
        ? hoverInstance.item.group.items
        : [hoverInstance.item];
      for (const item of itemsToUpdate) {
        item.isHovered = true;

        updateItemColor(
          item,
          instanceColor.array,
          items.hasFilters,
          items.useItemColors
        );
      }
      instanceColor.needsUpdate = true;
    }

    return () => {
      if (hoverInstance != null) {
        for (const item of itemsToUpdate) {
          item.isHovered = false;
          updateItemColor(
            item,
            instanceColor.array,
            items.hasFilters,
            items.useItemColors
          );
        }
        instanceColor.needsUpdate = true;
      }
    };
  }, [hoverInstance, items, items.filterTimestamp, itemsAreGrouped]);
}

export default useColors;
