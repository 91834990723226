import React, { useRef } from 'react';
import { a, useSpring } from '@react-spring/three';
import { animationSpringConfig } from '../constants';

const SelectedItem = ({ selectedInstance }) => {
  const selectedGroupRef = useRef();
  const spotLightRef = useRef();
  const { spotLightPosition, selectedPosition } = useSpring({
    spotLightPosition: selectedInstance
      ? [
          selectedInstance.item.x,
          selectedInstance.item.y + selectedInstance.item.height + 30,
          selectedInstance.item.z + 30,
        ]
      : [0, 0, 0],
    selectedPosition: selectedInstance
      ? [
          selectedInstance.item.x,
          selectedInstance.item.y + selectedInstance.item.height,
          selectedInstance.item.z,
        ]
      : [0, 0, 0],
    // config: animationSpringConfig,
    config: { mass: 1, tension: 210, friction: 20 },
  });

  return (
    <>
      <a.group ref={selectedGroupRef} position={selectedPosition}>
        {false && spotLightRef.current && (
          <spotLightHelper args={[spotLightRef.current]} scale={[5, 5, 5]} />
        )}
        {false && (
          <mesh>
            <boxBufferGeometry args={[2, 2, 2]} attach="geometry" />
            <meshStandardMaterial attach="material" color="#ffff00" wireframe />
          </mesh>
        )}
      </a.group>
      {selectedInstance != null && (
        <>
          <a.spotLight
            ref={spotLightRef}
            castShadow
            intensity={0.4}
            angle={Math.PI / 6}
            position={spotLightPosition}
            target={selectedGroupRef.current}
            penumbra={1}
            color="#fffc88"
            shadow-mapSize-width={2048}
            shadow-mapSize-height={2048}
          />
          <pointLight
            distance={15}
            position={[
              selectedInstance.item.x,
              selectedInstance.item.y,
              selectedInstance.item.z,
            ]}
            intensity={0.5}
            decay={1}
            color="#0000ff"
          />
        </>
      )}
    </>
  );
};

export default SelectedItem;
