import React from 'react';
import ReactDOM from 'react-dom';
import Downshift from 'downshift';
import cx from 'classnames';
import CloseIcon from './CloseIcon';
import Popover, { positionMatchWidth } from '@reach/popover';
/*
ursor: pointer;
    display: block;
    color: inherit;
    font: inherit;
    text-decoration: initial;
    padding: 5px 20px;
} */

const SearchItem = ({ item, index, isHighlighted, isSelected, ...other }) => {
  return (
    <div
      className={cx(
        'py-1 px-5 cursor-pointer leading-relaxed whitespace-no-wrap',
        {
          'text-yellow-400 bg-gray-800': isSelected,
          'text-yellow-400 bg-gray-800': isHighlighted,
        }
      )}
      {...other}
    >
      {item.label}
    </div>
  );
};

class Portal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.portalRoot = document.body;
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    this.portalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    this.portalRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

const SearchItems = React.forwardRef(
  ({ item, index, children, ...other }, ref) => {
    return (
      <div
        className="bg-gray-900  z-20 rounded overflow-auto text-gray-300 text-lg"
        style={{ maxHeight: '300px', minWidth: '257px' }}
        ref={ref}
        {...other}
      >
        {children}
      </div>
    );
  }
);

function stateReducer(state, changes) {
  switch (changes.type) {
    // @pbeshai modification: auto select first item when typing so we
    // can just press enter to select it.
    case Downshift.stateChangeTypes.changeInput:
      // note we set highlightIndex to 0 here so we always have the
      // top match selected even if there was a previously selected one
      // (e.g. the user moused over item 5 before typing). If we do not
      // it is possible that the existing index is no longer available
      // due to the list being filtered and so we'd see nothing highlighted.
      if (changes.inputValue !== '') {
        return {
          ...changes,
          highlightedIndex: 0,
        };
      }
      return changes;

    default:
      return changes;
  }
}

const AutocompleteSelect = ({
  onChange,
  initialSelectedItem,
  items,
  ...other
}) => {
  const inputRef = React.useRef(null);

  return (
    <>
      <span style={{ display: 'none' }} id="autocomplete-select-label">
        Search for Players
      </span>
      <Downshift
        role="select"
        id="autocomplete-select"
        onChange={onChange}
        initialSelectedItem={initialSelectedItem}
        itemToString={item => (item ? item.label : '')}
        stateReducer={stateReducer}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          getRootProps,
          isOpen,
          inputValue,
          highlightedIndex,
          selectedItem,
          clearSelection,
          openMenu,
        }) => {
          let filteredItems;
          if (isOpen && inputValue) {
            const matchRegex = new RegExp(
              `(?:^|\\b)${inputValue
                .replace(/[^\w\s]/g, '') // remove non word characters
                .split(' ')
                .map(d => `${d}.*`)
                .join(' ')}`,
              'i'
            );
            filteredItems = items.filter(d => matchRegex.test(d.label));
          } else {
            filteredItems = items;
          }
          return (
            <div
              className="relative"
              {...getRootProps({}, { suppressRefError: true })}
            >
              <div className="flex">
                <div className="relative">
                  {selectedItem != null && (
                    <span className="absolute block tracking-wide text-xs  text-gray-600 ">
                      Player
                    </span>
                  )}
                  <input
                    placeholder={'Player'}
                    className={cx(
                      'px-2 py-2  -mx-2 w-full bg-transparent placeholder-white hover:placeholder-gray-400 text-lg text-yellow-400 rounded outline-none focus:bg-gray-900 focus:shadow-outline',
                      { 'pt-5': selectedItem != null }
                    )}
                    ref={inputRef}
                    {...getInputProps({ onFocus: openMenu })}
                  />
                </div>
                {inputValue ? (
                  <button
                    className="leading-none p-3 -mr-3 ml-2 hover:text-gray-400 text-gray-700 focus:outline-none"
                    onClick={() => onChange(undefined)}
                    aria-label="Clear selection"
                    onClick={clearSelection}
                  >
                    <CloseIcon width={10} />
                  </button>
                ) : null}
              </div>
              <Popover targetRef={inputRef} position={positionMatchWidth}>
                <SearchItems {...getMenuProps({}, { suppressRefError: true })}>
                  {isOpen
                    ? filteredItems.slice(0, 20).map((item, index) => (
                        <SearchItem
                          item={item}
                          index={index}
                          isHighlighted={highlightedIndex === index}
                          isSelected={selectedItem === item}
                          {...getItemProps({
                            key: `${item.type}-${item.value}`,
                            index,
                            item,
                          })}
                        />
                      ))
                    : null}
                </SearchItems>
              </Popover>
            </div>
          );
        }}
      </Downshift>
    </>
  );
};

export default AutocompleteSelect;
