import { range, csv, extent } from 'd3';
import { Stats, teams, teamMapper } from './constants';
import { max, sum, rollup, descending, ascending, bin } from 'd3-array';

function fetchPlayerTeams() {
  return csv('/data/nba_season_teams_1950_2020.csv', d => {
    d.team_id = +d.team_id;
    d.team = teams[d.team_id];
    // combine teams that changed locations or names
    if (teamMapper[d.team_id] != null) {
      d.mergeTeamId = teamMapper[d.team_id];
    }
    d.player_id = +d.player_id;
    d.num_seasons = +d.num_seasons;
    for (const stat of Stats) {
      d[stat.value] = +d[stat.value];
    }
    d.total_fgm = +d.total_fgm;
    d.total_fg3m = +d.total_fg3m;

    d.total_gp = +d.total_gp;
    try {
      d.seasons = JSON.parse(d.seasons);
    } catch (e) {
      console.error(e);
      console.log(d.seasons, d);
      d.seasons = [1934];
    }
    return d;
  }).then(playerTeams => {
    // merge teammapper teams
    for (const d of playerTeams) {
      if (d.mergeTeamId != null) {
        // needs to be merged
        const mergeItem = playerTeams.find(
          x => x.player_id === d.player_id && x.team_id === d.mergeTeamId
        );
        if (mergeItem != null) {
          // merge into existing item and remove this
          d.markForRemoval = true;
          for (const stat of Stats) {
            mergeItem[stat.value] += d[stat.value];
          }
          mergeItem.seasons = combineAndSortSeasons([mergeItem, d]);
        } else {
          // otherwise replace with the merged team
          d.team_id = d.mergeTeamId;
          d.team = teams[d.team_id];
        }
      }
    }

    return playerTeams.filter(d => !d.markForRemoval);
  });
}

function combineAndSortSeasons(singlePlayerTeams) {
  const combined = singlePlayerTeams
    .reduce((accum, playerTeam) => [...accum, ...playerTeam.seasons], [])
    .filter((d, i, a) => a.indexOf(d) === i);
  combined.sort();
  return combined;
}

export function fetchData() {
  return Promise.all([fetchPlayerTeams()]).then(([playerTeams]) => {
    playerTeams.extents = {};
    playerTeams.extents.overall = [0, 1];

    // compute extents across all stats
    for (const stat of Stats) {
      playerTeams.extents[stat.value] = extent(playerTeams, d => d[stat.value]);
      playerTeams.extents.overall[1] = Math.max(
        playerTeams.extents.overall[1],
        playerTeams.extents[stat.value][1]
      );
    }

    const players = Array.from(
      rollup(
        playerTeams,
        d => ({
          value: d[0].player_id,
          label: d[0].player_name,
          seasons: combineAndSortSeasons(d),
          totalStats: Stats.reduce(
            (accum, stat) => (
              (accum[stat.value] = sum(d, d => d[stat.value])), accum
            ),
            {}
          ),
          items: (d.sort((a, b) => descending(a.seasons[0], b.seasons[0])), d),
        }),
        d => d.player_id
      ).values()
    );

    // store a reference to the group object on each item for access in tooltips and hover/select coloring
    for (const player of players) {
      for (const item of player.items) {
        item.group = player;
      }
    }

    const totalExtents = { overall: [0, 1] };

    // compute extents across all stats
    for (const stat of Stats) {
      totalExtents[stat.value] = extent(players, d => d.totalStats[stat.value]);
      totalExtents.overall[1] = Math.max(
        totalExtents.overall[1],
        totalExtents[stat.value][1]
      );
    }

    const playerTeamBins = { stats: {} };
    const totalBins = { stats: {} };
    const numBins = 20;
    for (const stat of Stats) {
      const statKey = stat.value;
      const statBins = bin()
        .domain(playerTeams.extents[statKey])
        .value(d => d[statKey])
        .thresholds((data, min, max) =>
          range(numBins).map(t => min + (t / numBins) * (max - min))
        )(playerTeams);

      playerTeamBins.stats[statKey] = {
        bins: statBins.map(d => ({ n: d.length, x0: d.x0, x1: d.x1 })),
        xExtent: playerTeams.extents[statKey],
        yExtent: [0, max(statBins, d => d.length)],
      };

      // compute for overall
      const statBinsTotal = bin()
        .domain(totalExtents[statKey])
        .value(d => d.totalStats[statKey])
        .thresholds((data, min, max) =>
          range(numBins).map(t => min + (t / numBins) * (max - min))
        )(players);

      totalBins.stats[statKey] = {
        bins: statBinsTotal.map(d => ({ n: d.length, x0: d.x0, x1: d.x1 })),
        xExtent: totalExtents[statKey],
        yExtent: [0, max(statBinsTotal, d => d.length)],
      };
    }
    playerTeamBins.yExtentOverall = [
      0,
      max(
        Object.keys(playerTeamBins.stats),
        key => playerTeamBins.stats[key].yExtent[1]
      ),
    ];
    totalBins.yExtentOverall = [
      0,
      max(Object.keys(totalBins.stats), key => totalBins.stats[key].yExtent[1]),
    ];

    console.log('@@@ got players', players);
    console.log('@@@ got bins', playerTeamBins, totalBins);
    return {
      playerTeams,
      extents: playerTeams.extents,
      totalExtents,
      players,
      playerTeamBins,
      totalBins,
    };
  });
}
