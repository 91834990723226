import { descending } from 'd3-array';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Stats } from './constants';
import DetailsPanel from './DetailsPanel';
import HelpPanel from './HelpPanel';
import applyFilters from './filters';
import nbaLogoImgSrc from './NBA.png';
import Sidebar from './Sidebar';
import ThreeVis from './ThreeVis/ThreeVis';
import Tooltip from './Tooltip';
import useTitle from './useTitle';
import Loader from './Loader';

const markSelected = (selectedInstance, itemsAreGrouped, selected) => {
  if (selectedInstance == null) return;

  for (const item of selectedInstance.item.group.items) {
    item.isSelected = false;
    if (selected) {
      if (itemsAreGrouped || item === selectedInstance.item) {
        item.isSelected = true;
      }
    }
  }
};
const HomeView = ({
  playerTeams,
  players,
  teams,
  playerTeamBins,
  totalBins,
}) => {
  useTitle();

  const [selectedInstance, setSelectedInstance] = useState(null);
  const [hoverInstance, setHoverInstance] = useState(null);
  const [layout, setLayout] = useState('players');
  const [playerFilter, setPlayerFilter] = useState(undefined);
  const [teamFilter, setTeamFilter] = useState(undefined);
  const [hideFilteredOut, setHideFilteredOut] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [statKey, setStatKey] = useState('total_pts');

  const items = playerTeams;
  const itemsAreGrouped = layout === 'players' || layout === 'players-phyllo';

  // take the first occurrence for now since currently we only use the ID
  players = useMemo(() => {
    players.sort((a, b) =>
      descending(a.totalStats[statKey], b.totalStats[statKey])
    );
    return players;
  }, [players, statKey]);

  useEffect(() => {
    markSelected(selectedInstance, itemsAreGrouped, true);
  }, [itemsAreGrouped, selectedInstance]);

  const handleSelectInstance = useCallback(
    newSelectedInstance => {
      const prevSelectedInstance = selectedInstance;
      console.log(prevSelectedInstance, newSelectedInstance, itemsAreGrouped);
      markSelected(prevSelectedInstance, itemsAreGrouped, false);

      if (newSelectedInstance) {
        markSelected(newSelectedInstance, itemsAreGrouped, true);
      }
      setSelectedInstance(newSelectedInstance);
    },
    [selectedInstance, itemsAreGrouped]
  );

  const handleSelectItem = useCallback(item => handleSelectInstance({ item }), [
    handleSelectInstance,
  ]);

  useEffect(() => {
    let itemsToUpdate;
    if (selectedInstance != null) {
      itemsToUpdate = itemsAreGrouped
        ? selectedInstance.item.group.items
        : [selectedInstance.item];
      for (const item of itemsToUpdate) {
        item.isSelected = true;
      }
    }

    return () => {};
  }, [itemsAreGrouped, selectedInstance]);

  const filterTimestamp = useMemo(() => {
    const filters = {
      playerFilter,
      teamFilter,
    };
    applyFilters(items, filters, {
      players,
      treatPlayersAsOne: itemsAreGrouped,
    });
    return items.filterTimestamp;
  }, [items, playerFilter, teamFilter, players, itemsAreGrouped]);

  const selectedItem =
    selectedInstance == null ? undefined : selectedInstance.item;

  const hoverItem = hoverInstance == null ? undefined : hoverInstance.item;

  const visRef = useRef();
  const handleResetCamera = () => {
    visRef.current.resetCamera();
  };

  const handleChangePlayerFilter = playerId => {
    setPlayerFilter(playerId);
    if (playerId != null) {
      handleSelectInstance({ item: items.find(d => d.player_id === playerId) });
    }
  };

  // if (selectedInstance) {
  //   console.log(
  //     'got selectedInstance',
  //     selectedInstance.item.team.full_name,
  //     selectedInstance,
  //     selectedItem
  //   );
  // }

  // insist on having the nba logo image loaded before doing anything
  const [loading, setLoading] = useState(true);
  const [nbaLogoImage, setNbaLogoImage] = useState(null);
  useEffect(() => {
    const nbaLogoImage = new Image();
    nbaLogoImage.onload = function() {
      // _img.src = this.src;
      setNbaLogoImage(nbaLogoImage);
      setLoading(false);
    };
    nbaLogoImage.src = nbaLogoImgSrc;
  }, []);

  const handleCloseDetails = useCallback(() => handleSelectInstance(null), [
    handleSelectInstance,
  ]);

  return (
    <div className="h-screen overflow-hidden flex">
      <Tooltip
        item={hoverItem}
        statKey={statKey}
        x={hoverInstance && hoverInstance.mouse[0]}
        y={hoverInstance && hoverInstance.mouse[1]}
        itemsAreGrouped={itemsAreGrouped}
      />
      <Sidebar
        layoutItem={layout}
        onChangeLayoutItem={setLayout}
        stats={Stats}
        statKey={statKey}
        onStatKeyChange={setStatKey}
        players={players}
        playerFilter={playerFilter}
        onPlayerFilterChange={handleChangePlayerFilter}
        teams={teams}
        teamFilter={teamFilter}
        onTeamFilterChange={setTeamFilter}
        hideFilteredOut={hideFilteredOut}
        onHideFilteredOut={setHideFilteredOut}
        onShowHelp={() => setShowHelp(!showHelp)}
      />

      <div className="relative bg-gray-900 flex-grow ">
        <div className="absolute inset-0 h-full z-0">
          {loading ? (
            <Loader />
          ) : (
            <ThreeVis
              ref={visRef}
              playerTeams={playerTeams}
              teams={teams}
              players={players}
              layoutKey={layout}
              statKey={statKey}
              selectedInstance={selectedInstance}
              onSelectInstance={handleSelectInstance}
              nbaLogoImage={nbaLogoImage}
              hoverInstance={hoverInstance}
              onHoverInstance={setHoverInstance}
              itemsAreGrouped={itemsAreGrouped}
              hideFilteredOut={hideFilteredOut}
            />
          )}
        </div>
        <button
          onClick={handleResetCamera}
          className="absolute top-0 right-0 mr-2 my-2 p-1 text-xs max-w-xs uppercase tracking-wide text-white opacity-50 rounded hover:opacity-50 hover:bg-black transition-all focus:outline-none"
          style={{ width: '60px' }}
        >
          Reset Camera
        </button>

        <DetailsPanel
          item={selectedItem}
          onSelectItem={handleSelectItem}
          onClose={handleCloseDetails}
          className="absolute inset-x-0 bottom-0 z-10"
          playerTeamBins={playerTeamBins}
          totalBins={totalBins}
          itemsAreGrouped={itemsAreGrouped}
        />
        {showHelp && <HelpPanel onClose={() => setShowHelp(false)} />}
      </div>
    </div>
  );
};

export default HomeView;
