import 'pepjs'; // pointer events polyfill  https://github.com/jquery/PEP
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import './tailwind.dist.css';
import './index.css';
import App from './App';

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA-55938435-2');
}

const render = App => {
  ReactDOM.render(<App />, document.getElementById('root'));
};

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}
